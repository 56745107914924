<template>
  <div>
    <div v-if="authUserPermission['workbench-view']">
      <base-header class="pb-6">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0"></h6>
          </div>
        </div>
      </base-header>
      <div class="container-fluid mt--8">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-lg-3" style="display: flex; align-items: center">
                <custom-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></custom-pagination>

                <p class="ml-3">
                  {{
                    tt("page_info", {
                      size:(table.page.current_page - 1) * table.page.per_page + table.data.length,
                      total: table.total
                    })
                  }}
                </p>
              </div>
              <div class="col-lg-9 text-lg-right">
                <button @click="checkDuplicate" class="btn btn-sm btn-dark" :disabled="isClicked == false">
                  <i class="fa fa-database" aria-hidden="true"></i>
                </button>
                <button @click="copyValue" class="btn btn-sm btn-dark" :disabled="isClicked == false">
                  <i class="fa fa-clone" aria-hidden="true"></i>
                </button>
                <button @click="tab('source')" class="btn btn-sm btn-dark">
                  <i class="fa fa-book" aria-hidden="true"></i>
                </button>
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link btn btn-dark btn-sm">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div class="container">
                      <div class="" v-for="(checked, key) in checkedColumn" :key="'checked' + key">
                        <el-checkbox :checked="checkedColumn[key]" @change="changeChecked(key)">{{ tt(key)
                          }}</el-checkbox>
                      </div>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <!-- SOURCE -->
              <div v-if="showSource == true">
                <div ref="draggableContainer" id="draggable-container">
                  <div>
                    <div class="card">
                      <div class="card-header bg-primary source" id="draggable-header" @mousedown="dragMouseDown">
                        <div class="row">
                          <div class="col-10 text-white">
                            {{ partSource.material_number }}
                          </div>
                          <div class="col-2 text-right">
                            <i class="fa fa-times text-white" @click="showSource = false"></i>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div v-if="partSource != null">
                          <div class="row">
                            <div class="col-5">
                              <h5 class="header-source">
                                {{ tt("material_type") }}
                              </h5>
                            </div>
                            <div class="col-7">
                              <h5 class="header-source">
                                : {{ partSource.material_type }}
                              </h5>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-5">
                              <h5 class="header-source">
                                {{ tt("material_group") }}
                              </h5>
                            </div>
                            <div class="col-7">
                              <h5 class="header-source">
                                : {{ partSource.material_group }}
                              </h5>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-5">
                              <h5 class="header-source">{{ tt("uom") }}</h5>
                            </div>
                            <div class="col-7">
                              <h5 class="header-source">
                                : {{ partSource.uom }}
                              </h5>
                            </div>
                          </div>

                          <!-- <div class="row">
                            <div class="col-5">
                              <h5 class="header-source">
                                {{ tt("item_number") }}
                              </h5>
                            </div>
                            <div class="col-7">
                              <h5 class="header-source">
                                : {{ partSource.item_number }}
                              </h5>
                            </div>
                          </div> -->

                          <!-- <div class="row">
                            <div class="col-5">
                              <h5 class="header-source">
                                {{ tt("search_text") }}
                              </h5>
                            </div>
                            <div class="col-7">
                              <h5 class="header-source">
                                : {{ partSource.search_text }}
                              </h5>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-5">
                              <h5 class="header-source">
                                {{ tt("brand") }}
                              </h5>
                            </div>
                            <div class="col-7">
                              <h5 class="header-source">
                                : {{ partSource.brand }}
                              </h5>
                            </div>
                          </div> -->

                          <div class="row">
                            <div class="col-5">
                              <h5 class="header-source">
                                {{ tt("short_description") }}
                              </h5>
                            </div>
                            <div class="col-7">
                              <h5 class="header-source">
                                : {{ partSource.short_description }}
                              </h5>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-5">
                              <h5 class="header-source">
                                {{ tt("long_description") }}
                              </h5>
                            </div>
                            <div class="col-7">
                              <h5 class="header-source">
                                : {{ partSource.long_description }}
                              </h5>
                            </div>
                          </div>
                        </div>

                        <h5 class="text-center bg-light mt-4" v-else>
                          {{ tt("source_empty") }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt--3">
            <el-table border min-height="250px" max-height="250px" ref="multipleTable" :data="table.data" class="customTable"
              header-row-class-name="thead-light" v-if="!onLoad.table" highlight-current-row
              @current-change="handleCurrentChange" @selection-change="handleSelectionChange"
              :row-class-name="tableRowClassName">
              <el-table-column type="selection" width="55"></el-table-column>
              <!-- COLUMN MATERIAL NUMBER -->
              <template v-if="checked.material_number">
                <el-table-column :label="tt('mat_no')" prop="material_number" min-width="125px"
                  v-if="checked.material_number" sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN NEW MATERIAL NUMBER -->
              <template v-if="checked.new_material_number">
                <el-table-column :label="tt('new_mat_no')" prop="new_material_number"
                  v-if="checkedColumn['new_material_number']" min-width="150px" sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- ITEM NAME -->
              <template v-if="checked.item_name">
                <el-table-column :label="tt('item_name')" prop="item_name" v-if="checked.item_name" min-width="250px"
                  sortable></el-table-column>
              </template>

              <!-- COLUMN SHORT DESCRIPTION -->
              <template v-if="checked.short_description">
                <el-table-column :label="tt('short_description')" v-if="checked.short_description"
                  prop="short_description" min-width="410px" sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN LEN -->
              <template v-if="checked.short_length">
                <el-table-column :label="tt('len')" prop="short_length" v-if="checked.short_length" min-width="95px"
                  sortable>
                </el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN SHORT DES 2 -->
              <template v-if="checked.short_description_2">
                <el-table-column :label="tt('short_description_2')" v-if="checked.short_description_2"
                  prop="short_description_2" min-width="410px" sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN SHORT LENGTH DES 2 -->
              <template v-if="checked.short_length_2">
                <el-table-column :label="tt('len')" prop="short_length_2" v-if="checked.short_length_2" min-width="95px"
                  sortable>
                </el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN source_description -->
              <template v-if="checked.source_description">
                <el-table-column :label="tt('source_description')" prop="source_description"
                  v-if="checked.source_description" min-width="410px" sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN uom -->
              <template v-if="checked.uom">
                <el-table-column :label="tt('uom')" prop="uom" min-width="110px" v-if="checked.uom"
                  sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN material_type -->
              <template v-if="checked.material_type">
                <el-table-column :label="tt('m_type')" prop="material_type" v-if="checked.material_type"
                  min-width="120px" sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN material_group -->
              <template v-if="checked.material_group">
                <el-table-column :label="tt('m_group')" prop="material_group" v-if="checked.material_group"
                  min-width="130px" sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN cat_type -->
              <template v-if="checked.cat_type">
                <el-table-column :label="tt('cat_type')" prop="cat_type" min-width="130px" v-if="checked.cat_type"
                  sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN cat_status -->
              <template v-if="checked.cat_status">
                <el-table-column :label="tt('cat_status')" prop="cat_status" min-width="150px" v-if="checked.cat_status"
                  sortable></el-table-column>
              </template>
              <!-- END -->

              <!-- COLUMN status_report -->
              <template v-if="checked.status_report">
                <el-table-column :label="tt('report')" prop="status_report" min-width="150px"
                  v-if="checked.status_report" sortable></el-table-column>
              </template>
              <!-- END -->
              <!-- <el-table-column
                :label="tt('priority')"
                prop="priority"
                min-width="150px"
                v-if="checked.priority"
                sortable
              ></el-table-column> -->

              <!-- COLUMN cat_by -->
              <template v-if="checked.catalogued_by">
                <el-table-column :label="tt('cat_by')" prop="name" min-width="150px" v-if="checked.catalogued_by"
                  sortable></el-table-column>
              </template>
              <!-- END -->
            </el-table>
            <skeleton-loading v-else></skeleton-loading>
          </div>
          <div style="margin-top: 20px;">
            <div class="col-md-12">
              <div class="mb-2">
                <div class="nav nav-tabs" id="nav-tab" role="tablist"
                  style="font-size: 11px;overflow-x: auto;overflow-y: hidden;flex-wrap: nowrap; text-transform: uppercase;">
                  <a :class="tabs.basic_data ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab"
                    @click="tab('basic_data')" role="tab" aria-controls="nav-home" :aria-selected="tabs.basic_data">
                    <b>{{ tt("basic_data_classification") }}</b>
                  </a>
                  <a :class="tabs.mpn ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab"
                    @click="tab('mpn')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.mpn">
                    <b>{{ tt("mpn") }} & {{ tt("equipment") }}</b>
                  </a>
                  <a :class="tabs.po_text ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab"
                    @click="tab('po_text')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.po_text">
                    <b>{{ tt("po_text") }}</b>
                  </a>
                  <a :class="tabs.plant ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab"
                    @click="tab('plant')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.plant">
                    <b>{{ tt("plant_storage_location") }}</b>
                  </a>
                  <a :class="tabs.attachment ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab"
                    @click="tab('attachment')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.attachment">
                    <b>{{ tt("attachment") }}</b>
                  </a>
                  <!-- <a :class="tabs.source ? 'nav-item nav-link' : 'nav-item nav-link'" data-toggle="tab" @click="tab('source')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.source"><b>{{ tt('source') }}</b></a> -->
                </div>
              </div>

              <!-- BASIC DATA & CLASS -->
              <div v-if="tabs.basic_data">
                <div class="row">
                  <!-- DATA MATERIAL -->
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-6">
                            <h4>
                              <b>{{ tt("basic_data") }}</b>
                              <span class="text-danger"> *</span>
                            </h4>
                          </div>
                          <div class="col-6 text-right"></div>
                        </div>
                      </div>
                      <!-- <div style="height:600px;overflow-y: scroll;"> -->
                      <div class="card-body" style="height:600px;overflow-y: scroll;"
                        v-if="!onLoad.detail && Array.isArray(configBasicData)">
                        <div class="row">
                          <!-- ITEM NAME -->
                          <div class="col-12" v-show="configBasicData.includes('item_name')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("item_name") }}
                              <span class="text-danger">&nbsp;*</span></label>
                            <base-input :name="tt('item_name')" rules="required">
                              <el-select id="tableSelect" :disabled="isClicked == false" v-model="dataMaterial.inc"
                                :placeholder="tt('minimum_type_char', { total: 2 })" :remote-method="querySearchItemName" :loading="selectSearch.sitLoading" filterable remote reserve-keyword
                                @change="showCharValue()">
                                <el-option v-for="si in standardItemName" :key="si.inc" :value="si.inc" :selected="dataMaterial.inc == si.inc + ' - ' + si.item_name" :label="si.inc + ' - ' + si.item_name"></el-option>
                              </el-select>
                            </base-input>
                            <!-- <base-input :placeholder="tt('item_name')" v-model="dataMaterial.item_name" input-classes="form-control-sm"></base-input> -->
                          </div>

                          <!-- GROUP CLASS -->
                          <div class="col-12" v-show="configBasicData.includes('group_class')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("group_class") }}
                              <span class="text-danger">&nbsp;*</span></label>
                            <base-input :name="tt('group_class')" rules="required">
                              <!-- <el-select id="tableSelect" :disabled="isClicked == false" class="select-danger" v-model="dataMaterial.group_class" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchGroupClass" :loading="selectSearch.ngcLoading" filterable remote reserve-keyword>
                                                                  <el-option class="select-danger" :value="ngc.nsc" :label="ngc.nsc +' - '+ ngc.description" :selected="dataMaterial.group_class == ngc.nsc +' - '+ ngc.description" :key="ngc.nsc" v-for="ngc in  natoGroupClass"></el-option>
                                                              </el-select> -->
                              <el-select id="tableSelect" :disabled="isClicked == false"
                                v-model="dataMaterial.group_class" :placeholder="tt('nato_group_class')"
                                :loading="selectSearch.ngcLoading">
                                <el-option v-for="ngc in natoGroupClass" :key="ngc.nsc"
                                  :value="ngc.nsc + ' - ' + ngc.description" :selected="dataMaterial.group_class == ngc.nsc + ' - ' + ngc.description">{{ngc.nsc + " - " + ngc.description}}</el-option>
                              </el-select>
                            </base-input>
                            <!-- <base-input :placeholder="tt('group_class')" v-model="dataMaterial.group_class" input-classes="form-control-sm" ></base-input> -->
                          </div>

                          <!-- UNSPSC -->
                          <fieldset v-show="configCodification == 'UNSPSC'">
                            <legend class="float-none w-auto">
                              <h5>{{ tt('unspsc') }}<span class="text-danger">&nbsp;*</span></h5>
                            </legend>
                            <div class="row mt--3">
                              <div class="col-12">
                                <label for="" class="form-control-label ml-2" style="font-size:12px">{{ tt("segment") }}
                                  <span class="text-danger">&nbsp;*</span></label>
                                <base-input :name="tt('unspsc')">
                                  <el-select v-model="dataMaterial.sg_code" :placeholder="tt('segment')" class="ml-2"
                                    id="tableSelect" type="text" :disabled="isClicked == false || selectSearch.unspsc"
                                    :loading="selectSearch.unspsc">
                                    <el-option v-for="sg in segmentCodes" :value="sg.identity_relation_code"
                                      :label="sg.code + ' - ' + sg.title" :key="sg.id" :selected="dataMaterial.sg_code == sg.identity_relation_code"></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                              <div class="col-12 mt--4">
                                <label for="" class="form-control-label ml-2" style="font-size:12px">{{ tt("family") }}
                                  <span class="text-danger">&nbsp;*</span></label>
                                <base-input>
                                  <el-select v-model="dataMaterial.fm_code" id="tableSelect" :placeholder="tt('family')"
                                    class="ml-2" type="text" :disabled="isClicked == false || selectSearch.unspsc"
                                    :loading="selectSearch.unspsc">
                                    <el-option v-for="fm in familyCodes" :value="fm.identity_relation_code"
                                      :label="fm.code + ' - ' + fm.title" :key="fm.id" :selected="dataMaterial.fm_code == fm.identity_relation_code"></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                              <div class="col-12 mt--4">
                                <label for="" class="form-control-label ml-2" style="font-size:12px">{{tt("classification") }}
                                  <span class="text-danger">&nbsp;*</span></label>
                                <base-input>
                                  <el-select v-model="dataMaterial.cl_code" id="tableSelect"
                                    :placeholder="tt('classification')" class="ml-2" type="text"
                                    :disabled="isClicked == false || selectSearch.unspsc"
                                    :loading="selectSearch.unspsc">
                                    <el-option v-for="cl in classificationCodes" :value="cl.identity_relation_code"
                                      :key="cl.id" :label="cl.code + ' - ' + cl.title" :selected="dataMaterial.cl_code == cl.identity_relation_code"></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                              <div class="col-12 mt--4">
                                <label for="" class="form-control-label ml-2" style="font-size:12px">{{ tt("commodity")
                                  }}
                                  <span class="text-danger">&nbsp;*</span></label>
                                <base-input>
                                  <el-select v-model="dataMaterial.cm_code" id="tableSelect"
                                    :placeholder="tt('commodity')" class="ml-2" type="text"
                                    :loading="selectSearch.unspsc"
                                    :disabled="isClicked == false || selectSearch.unspsc">
                                    <el-option v-for="cm in commodityCodes" :value="cm.identity_relation_code"
                                      :label="cm.code + ' - ' + cm.title" :key="cm.id" :selected="dataMaterial.cm_code == cm.identity_relation_code"></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>
                          </fieldset>

                          <div class="col-12" v-show="configCodification == 'MESC'">
                            <div class="row">
                              <div class="col-12 mt-1">
                                <label for="" class="form-control-label" style="font-size:12px">{{ tt("mesc") }}
                                  <span class="text-danger">&nbsp;*</span></label>
                              </div>
                              <div class="col-12 mb-2">
                                <div class="input-group input-group-sm mb-3">
                                  <select v-model="dataMaterial.mesc.main" id="tableSelectInput"
                                    :disabled="isClicked == false" type="text" class="form-control" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm">
                                    <option v-for="mg in mescGroup.mainGroup" :value="mergeMesc(mg.code, mg.key)"
                                      :key="mg.key" :selected="dataMaterial.mesc.main == mergeMesc(mg.code, mg.key)">{{mg.code + " - " + mg.description}}</option>
                                  </select>
                                  <select v-model="dataMaterial.mesc.sub" id="tableSelectInput"
                                    :disabled="isClicked == false" type="text" class="form-control" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm">
                                    <option v-for="sg in mescGroup.subGroup" :value="mergeMesc(sg.code, sg.key)"
                                      :key="sg.key">{{sg.code + " - " + sg.description}}</option>
                                  </select>
                                  <select v-model="dataMaterial.mesc.subSub" id="tableSelectInput"
                                    :disabled="isClicked == false" type="text" class="form-control" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm">
                                    <option v-for="ssg in mescGroup.subSubGroup" :value="mergeMesc(ssg.code, ssg.key)"
                                      :key="ssg.key">{{ssg.code + " - " + ssg.description}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- MATERIAL TYPE -->
                          <div class="col-6" v-show="configBasicData.includes('material_type')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("material_type") }}
                              <span class="text-danger">&nbsp;*</span></label>
                            <base-input :name="tt('material_type')" rules="required">
                              <!-- <el-select id="tableSelect" :disabled="isClicked == false" class="select-danger" v-model="dataMaterial.material_type" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchMtype" :loading="selectSearch.mtLoading" filterable remote reserve-keyword>
                                                                  <el-option class="select-danger" :value="mt.code" :label="mt.code+' - '+mt.description" :key="mt.code" v-for="mt in  materialType" :selected="dataMaterial.material_type == mt.code+' - '+mt.description"></el-option>
                                                              </el-select> -->
                              <el-select id="tableSelect" :disabled="isClicked == false"
                                v-model="dataMaterial.material_type" :placeholder="tt('material_type')"
                                :loading="selectSearch.ngcLoading">
                                <el-option class="select-danger" :value="mt.code"
                                  :label="mt.code + ' - ' + mt.description" :key="mt.code" v-for="mt in materialType"
                                  :selected="dataMaterial.material_type == mt.code + ' - ' + mt.description"></el-option>
                              </el-select>
                            </base-input>
                            <!-- <base-input :placeholder="tt('material_type')" v-model="dataMaterial.material_type" input-classes="form-control-sm" ></base-input> -->
                          </div>
                          <!-- MATERIAL GROUP -->
                          <div class="col-6" v-show="configBasicData.includes('material_group')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("material_group") }}
                              <span class="text-danger">&nbsp;*</span></label>
                            <base-input :name="tt('material_group')" rules="required">
                              <!-- <el-select id="tableSelect" :disabled="isClicked == false" class="select-danger" v-model="dataMaterial.material_group" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchMgroup" :loading="selectSearch.mgLoading" filterable remote reserve-keyword>
                                                                  <el-option class="select-danger" :value="mg.code" :label="mg.code +' - '+ mg.description" :key="mg.code" v-for="mg in  materialGroup" :selected="mg.code +' - '+ mg.description== dataMaterial.material_group"></el-option>
                                                              </el-select> -->
                              <el-select id="tableSelect" :disabled="isClicked == false"
                                v-model="dataMaterial.material_group" :placeholder="tt('material_type')"
                                :loading="selectSearch.ngcLoading">
                                <el-option class="select-danger" :value="mg.code"
                                  :label="mg.code + ' - ' + mg.description" :key="mg.code" v-for="mg in materialGroup"
                                  :selected="mg.code + ' - ' + mg.description == dataMaterial.material_group"></el-option>
                              </el-select>
                            </base-input>
                          </div>

                          <!-- ITEM TYPE -->
                          <div class="col-6" v-show="configBasicData.includes('item_type')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("item_type") }}
                              <span class="text-danger">&nbsp;*</span></label>
                            <base-input :name="tt('item_type')" rules="required">
                              <el-select id="tableSelect" :disabled="isClicked == false" class="select-danger"
                                v-model="dataMaterial.item_type" :placeholder="tt('choose_item_type')"
                                @change="showCharValue()">
                                <el-option class="select-danger" :value="it.code"
                                  :label="it.code + ' - ' + it.description" :key="it.code" v-for="it in itemType"
                                  :selected="it.code + ' - ' + it.description == dataMaterial.item_type"></el-option>
                              </el-select>
                            </base-input>
                            <!-- <base-input :placeholder="tt('item_type')" v-model="dataMaterial.item_type" input-classes="form-control-sm" ></base-input> -->
                          </div>

                          <!-- UOM -->
                          <div class="col-6" v-show="configBasicData.includes('uom')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("unit_of_measure") }}
                              <span class="text-danger">&nbsp;*</span></label>
                            <base-input :name="tt('uom')" rules="required">
                              <el-select id="tableSelect" :disabled="isClicked == false" class="select-danger"
                                v-model="dataMaterial.uom" :placeholder="tt('minimum_type_char', { total: 1 })" :remote-method="querySearchUom" :loading="selectSearch.uomLoading" filterable remote reserve-keyword>
                                <el-option class="select-danger" :value="uom.code"
                                  :label="uom.code + ' - ' + uom.description" :key="uom.code + 'uom'"
                                  v-for="uom in unitOfMeasure" :selected="uom.code + ' - ' + uom.description == dataMaterial.uom"></el-option>
                              </el-select>
                            </base-input>
                          </div>

                          <!-- CATALOG TYPE -->
                          <div class="col-6" v-show="configBasicData.includes('catalog_type')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("catalog_type") }}
                              <span class="text-danger">&nbsp;*</span></label>
                            <base-input :name="tt('catalog_type')" rules="required">
                              <el-select id="tableSelect" :disabled="isClicked == false" class="select-danger"
                                v-model="dataMaterial.catalog_type" :placeholder="tt('minimum_type_char', { total: 1 })" :remote-method="querySearchCtype" :loading="selectSearch.ctLoading" filterable remote reserve-keyword>
                                <el-option class="select-danger" :value="ct.code"
                                  :label="ct.code + ' - ' + ct.description" :key="ct.code" v-for="ct in catalogType"
                                  :selected="dataMaterial.catalog_type == ct.code + ' - ' + ct.description"></el-option>
                              </el-select>
                            </base-input>
                          </div>
                          <!-- FAB NON FAB -->
                          <div class="col-6" v-show="configBasicData.includes('fab')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("fab_non_fab") }}</label>
                            <base-input :name="tt('fabric_or_non_fabrication')">
                              <el-select id="tableSelect" :disabled="isClicked == false" class="select-danger"
                                v-model="dataMaterial.fab_non_fab" :placeholder="tt('choose_fabric_or_non_fabrication')">
                                <el-option class="select-danger" :value="fnf" :label="fnf" :key="fnf"
                                  v-for="fnf in fabNonFab" :selected="fnf == dataMaterial.fab_non_fab"></el-option>
                              </el-select>
                            </base-input>
                          </div>

                          <!-- BOM NON -->
                          <div class="col-6" v-show="configBasicData.includes('bom')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("bom_non_bom") }}</label>
                            <base-input :name="tt('bom_or_non_bom')">
                              <el-select id="tableSelect" :disabled="isClicked == false" class="select-danger"
                                v-model="dataMaterial.bom_non_bom" :placeholder="tt('choose_bom_or_non_bom')">
                                <el-option class="select-danger" :value="bnb" :label="bnb" :key="bnb + 'bnb'"
                                  v-for="bnb in bomNonBom" :selected="bnb == dataMaterial.bom_non_bom"></el-option>
                              </el-select>
                            </base-input>
                          </div>
                          <!-- CRITICAL NON CRITICAL -->
                          <div class="col-6" v-show="configBasicData.includes('critical')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("crit_non_crit") }}</label>
                            <base-input :name="tt('critical_non_critical')">
                              <el-select id="tableSelect" :disabled="isClicked == false" class="select-danger"
                                v-model="dataMaterial.critical_non_critical" :placeholder="tt('choose_critical_non_critical')">
                                <el-option class="select-danger" :value="cnc" :label="cnc" :key="cnc"
                                  v-for="cnc in criticalNonCritical" :selected="cnc == dataMaterial.critical_non_critical"></el-option>
                              </el-select>
                            </base-input>
                          </div>

                          <div class="col-12" v-show="configBasicData.includes('internal_note')">
                            <label class="form-control-label" style="font-size: 12px">{{ tt("internal_note") }}</label>
                            <textarea class="form-control" rows="3" v-model="dataMaterial.note"
                              :disabled="isClicked == false"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="card-body" style="height:600px;overflow-y: scroll;" v-else-if="onLoad.detail">
                        <skeleton-loading></skeleton-loading>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <!-- CLASSIFICATION -->
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-6">
                            <h4>
                              <b>{{ tt("classification") }}</b>
                              <!-- <span class="text-danger"> *</span> -->
                            </h4>
                          </div>
                          <div class="col-6 text-right">
                            <base-button size="sm" type="default" :disabled="onLoad.detail || isClicked == false" @click="oldValue.show = true">
                              <i class="fa fa-server" aria-hidden="true"></i>
                            </base-button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card-body border" style="height:600px;"> -->
                      <div style="height:600px;;overflow-y: scroll;" id="tableClassification">
                        <skeleton-loading v-if="(!onLoad.classification || onLoad.detail)"></skeleton-loading>
                        <table class="table align-items-center table-flush" v-else>
                          <thead class="card-header bg-primary">
                            <tr>
                              <th class="text-white">
                                {{ tt("characteristic")
                                }}<span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("value")
                                }}<span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("view")
                                }}<span class="text-danger">&nbsp;*</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(dinc, key) in partCharValue.cin_code">
                              <td>
                                {{ partCharValue.characteristic[key]
                                }}<span class="text-danger" v-if="partCharValue.mandatory[key] === true">&nbsp;*</span>
                                <input :disabled="isClicked == false" type="hidden"
                                  v-model="partCharValue.cin_code[key]" />
                                <input :disabled="isClicked == false" type="hidden" v-model="partCharValue.code[key]" />
                              </td>
                              <td>
                                <el-autocomplete id="tableSelect" :disabled="isClicked == false"
                                  v-model="partCharValue.value[key]" :fetch-suggestions="getValue" @focus="selectedCinCode(partCharValue.cin_code[key])" size="small">
                                </el-autocomplete>
                              </td>
                              <td>
                                <input :disabled="isClicked == false" type="checkbox" name="check"
                                  v-model="partCharValue.view[key]" />
                                <span>
                                  <button
                                    style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;"
                                    v-if="partCharValue.multipleChar[key] == 2 && partCharValue.code[key] == ''">
                                    <i class="fa fa-plus-square" :disabled="isClicked == false" aria-hidden="true"
                                      style="font-size: 15px" @click="addMultipleChar(
                                        key,
                                        partCharValue.cin_code[key],
                                        partCharValue.characteristic[key],
                                        partCharValue.view[key],
                                        partCharValue.mandatory[key]
                                      )
                                      "></i>
                                  </button>

                                  <button
                                    style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;"
                                    v-else-if="partCharValue.multipleChar[key] == '' || partCharValue.code[key] == 1">
                                    <i class="fa fa-minus-square text-danger" :disabled="isClicked == false"
                                      aria-hidden="true" style="font-size: 15px" @click="removeMultipleChar(key)"></i>
                                  </button>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- MPN & Equipment -->
              <div v-else-if="tabs.mpn">
                <div class="row">
                  <!-- MPN -->
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-6">
                            <h4>
                              <b>{{ tt("mpn") }}</b>
                            </h4>
                          </div>
                          <div class="col-6 text-right">
                            <base-button size="sm" @click="addMpn()" :disabled="onLoad.detail || !isClicked">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </base-button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card-body"  style="height:600px;"> -->
                      <div style="height:600px;overflow-y:scroll;">
                        <table style="height:500px" class="table align-items-center  table-responsive table-flush"
                          v-if="!onLoad.detail">
                          <thead class="card-header bg-primary">
                            <tr>
                              <th class="text-white">
                                {{ tt("manufacture_code")
                                }}<span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("source_type")
                                }}<span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("manufacture_refference")
                                }}<span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("type")
                                }}<span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">{{ tt("notes") }}</th>
                              <th class="text-white">{{ tt("shortdes") }}</th>
                              <th class="text-white">#</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(mpn, key) in partMPN.manufacture_code" :key="mpn.manufacture_code">
                              <td>
                                <el-select id="tableSelect" :disabled="isClicked == false"
                                  v-model="partMPN.manufacture_code[key]" :placeholder="tt('minimum_type_char', { total: 2 })" :remote-method="querySearchManCode" :loading="selectSearch.mancodeLoading" filterable remote reserve-keyword>
                                  <el-option v-for="mancode in masterManufacture" :key="mancode.id" :label="mancode.manufacture_code + ' - ' + mancode.manufacture_name" :value="mancode.manufacture_code" :selected="mancode.manufacture_code == partMPN.manufacture_code[key]"></el-option>
                                </el-select>
                                <input :disabled="isClicked == false" v-model="partMPN.part_manufacture_ref_id[key]"
                                  type="hidden" />
                              </td>
                              <td>
                                <el-select id="tableSelect" :disabled="isClicked == false"
                                  v-model="partMPN.source_type_code[key]" :placeholder="tt('choose_source_type')">
                                  <el-option :value="st.code" v-for="st in sourceType" v-bind:key="st.id" :selected="st.code == partMPN.source_type_code[key] ">{{st.code + " - " + st.description}}</el-option>
                                </el-select>
                              </td>
                              <td>
                                <!-- <span>{{ partMPN.source_type_code[key]}}:</span> -->
                                <el-input id="tableSelect" :disabled="isClicked == false" type="text"
                                  :placeholder="tt('manufacture_refference')" v-model="partMPN.manufacture_ref[key]"
                                  style="width:250px;">
                                  <!-- <template slot="prepend" style="height:25px">{{ partMPN.source_type_code[key]}}</template> -->
                                </el-input>
                              </td>
                              <td>
                                <el-select id="tableSelect" :disabled="isClicked == false"
                                  v-model="partMPN.manufacture_type[key]" style="width:100px"
                                  :placeholder="tt('choose_manref_type')">
                                  <el-option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id" :selected="mt.code == partMPN.manufacture_type[key]">{{mt.code + " - " + mt.description}}</el-option>
                                </el-select>
                                <!-- <input :disabled="isClicked == false" :placeholder="tt('type')" v-model="partMPN.manufacture_type[key]" class="form-control form-control-sm" style="min-width:100px"> -->
                              </td>
                              <td>
                                <el-input id="tableSelect" :disabled="isClicked == false" :name="tt('note')"
                                  :placeholder="tt('note')" v-model="partMPN.manufacture_note[key]" rules="required"
                                  style="width:250px">
                                </el-input>
                              </td>
                              <td>
                                <el-checkbox id="tableSelect"
                                  :disabled="isClicked == false || partMPN.shortdes.includes(true) && partMPN.shortdes[key] != true"
                                  :name="tt('shortdes')" v-model="partMPN.shortdes[key]"></el-checkbox>
                              </td>
                              <td width="10">
                                <i class="fa fa-trash text-danger" @click="deleteMpn(key)"></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <skeleton-loading v-else></skeleton-loading>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <!-- EQUIPMENT -->
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-6">
                            <h4>
                              <b>{{ tt("up_equipment") }}</b>
                            </h4>
                          </div>
                          <div class="col-6 text-right">
                            <base-button size="sm" @click="addEquipment()" :disabled="onLoad.detail || !isClicked">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </base-button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card-body" style="height:600px;"> -->
                      <div style="height:600px;overflow-y:scroll;">
                        <table style="height:500px" class="table align-items-center  table-responsive table-flush"
                          v-if="!onLoad.detail">
                          <thead class="card-header bg-primary text-white">
                            <tr>
                              <th class="text-white">
                                {{ tt("plant_code") }}
                                <span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("equipment_code") }}
                                <span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("quantity_install") }}
                                <span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("drawing_number") }}
                                <span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("shortdes") }}
                                <span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">#</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(equipment, key) in partEquipment.equipment_code" :key="equipment.equipment_code">
                              <td>
                                <el-select id="tableSelect" :disabled="isClicked == false"
                                  v-model="partEquipment.plant_code[key]" style="width:250px"
                                  :placeholder="tt('choose_plant_code')">
                                  <el-option :value="pl" v-for="pl in partPlant.plant_code" v-bind:key="pl" :selected="pl == partEquipment.plant_code[key]">{{ pl }}</el-option>
                                </el-select>
                                <input :disabled="isClicked == false" type="hidden" v-model="partEquipment.id[key]" />
                              </td>
                              <td>
                                <el-select id="tableSelect" :disabled="isClicked == false"
                                  v-model="partEquipment.equipment_code[key]" :placeholder="tt('minimum_type_char', { total: 3 })" :remote-method="querySearchEquipment" :loading="selectSearch.equipmentLoading" filterable remote
                                  reserve-keyword style="width:200px">
                                  <el-option :label="ec.equipment_code + '-' + ec.equipment_name" :value="ec.equipment_code" v-for="ec in equipmentList" :key="'equipment' + ec.value" :selected="ec.equipment_code == partEquipment.equipment_code[key]"></el-option>
                                </el-select>
                              </td>
                              <td>
                                <el-input id="tableSelect" :disabled="isClicked == false"
                                  :placeholder="tt('quantity_install')" v-model="partEquipment.qty_installed[key]"
                                  input-classes="form-control-sm"></el-input>
                              </td>
                              <td>
                                <el-select id="tableSelect" :disabled="isClicked == false" v-model="partEquipment.drawing_number[key]" :placeholder="tt('minimum_type_char', { total: 3 })" :remote-method="querySearchDrawingNumber" :loading="selectSearch.drawingNumLoading" filterable remote reserve-keyword>
                                  <el-option :label="dm.drawing_number + ' - ' + dm.drawing_name" :value="dm.drawing_number" v-for="dm in drawingMaster" :key="'equipment' + dm.id"></el-option>
                                </el-select>
                              </td>
                              <td>
                                <el-checkbox id="tableSelect"
                                  :disabled="isClicked == false || partEquipment.shortdes.includes(true) && partEquipment.shortdes[key] != true"
                                  :name="tt('shortdes')" v-model="partEquipment.shortdes[key]"></el-checkbox>
                              </td>
                              <td width="10">
                                <i class="fa fa-trash text-danger" @click="deleteEquipment(key)"></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <skeleton-loading v-else></skeleton-loading>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- PLANT & STORAGE LOCATION -->
              <div v-else-if="tabs.plant">
                <div class="row">
                  <!-- PLANT -->
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-6">
                            <h4>
                              <b>{{ tt("up_plant") }}</b>
                            </h4>
                          </div>
                          <div class="col-6 text-right">
                            <base-button size="sm" @click="addPlant()" :disabled="onLoad.detail || !isClicked">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </base-button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card-body" style="height:600px;">  -->
                      <div style="height:600px;overflow-y:scroll;">
                        <table class="table align-items-center table-flush " v-if="!onLoad.detail">
                          <thead class="card-header bg-primary text-white">
                            <tr>
                              <th class="text-white">
                                {{ tt("company_code") }}
                                <span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">
                                {{ tt("plant_code") }}
                                <span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">#</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(plant, key) in partPlant.plant_code" :key="plant.plant_code">
                              <td>
                                <el-select id="tableSelect" :disabled="isClicked == false" style="width: 100%"
                                  v-model="partPlant.company_code[key]" :placeholder="tt('minimum_type_char', { total: 2 })" :remote-method="queryCompany" filterable remote reserve-keyword>
                                  <el-option :label="company.company_code + '-' + company.company_description" :value="company.company_code" v-for="company in companyCode" :key="company.company_code" :selected="company_code == partPlant.company_code[key]"></el-option>
                                </el-select>
                              </td>
                              <td>
                                <el-select id="tableSelect" :disabled="isClicked == false" style="width: 100%"
                                  v-model="partPlant.plant_code[key]" :placeholder="tt('minimum_type_char', { total: 2 })" :remote-method="queryPlant" filterable remote reserve-keyword>
                                  <el-option :label="pc.label" :value="pc.value" v-for="pc in plantList" :key="pc.id"
                                    :selected="pc.value == partPlant.plant_code[key]"></el-option>
                                </el-select>
                                <input :disabled="isClicked == false" type="hidden" v-model="partPlant.id[key]" />
                              </td>
                              <td width="10">
                                <i class="fa fa-trash text-danger" @click="deletePlant(key)"></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <skeleton-loading v-else></skeleton-loading>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <!-- STORAGE LOCATION -->
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-6">
                            <h4>
                              <b>{{ tt("up_storage_location") }}</b>
                            </h4>
                          </div>
                          <div class="col-6 text-right">
                            <base-button size="sm" @click="addStorageLocation()" :disabled="onLoad.detail || !isClicked">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </base-button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card-body" style="height:600px;"> -->
                      <div style="height:600px;overflow-y:scroll">
                        <table class="table align-items-center table-flush" v-if="!onLoad.detail">
                          <thead class="card-header bg-primary text-white">
                            <th class="text-white">
                              {{ tt("plant_code") }}
                              <span class="text-danger">&nbsp;*</span>
                            </th>
                            <th class="text-white">
                              {{ tt("location") }}
                              <span class="text-danger">&nbsp;*</span>
                            </th>
                            <th class="text-white">{{ tt("bin") }}</th>
                            <th class="text-white">{{ tt("soh") }}</th>
                            <th class="text-white">#</th>
                          </thead>
                          <tbody>
                            <tr v-for="(storage, key) in partStorageLoc.storage_location" :key="storage.storage_location">
                              <td>
                                <el-select id="tableSelect" :disabled="isClicked == false"
                                  v-model="partStorageLoc.plant_code[key]" style="width:250px"
                                  :placeholder="tt('choose_plant_code')">
                                  <el-option v-for="eq in partPlant.plant_code" :key="eq" :selected="eq == partStorageLoc.plant_code[key]" :value="eq">{{ eq }}</el-option>
                                </el-select>
                              </td>
                              <td>
                                <input :disabled="isClicked == false" type="hidden" v-model="partStorageLoc.id[key]" />
                                <el-select id="tableSelect" :disabled="isClicked == false"
                                  v-model="partStorageLoc.storage_location[key]"
                                  :placeholder="tt('choose_storage_location')" style="min-width:100px">
                                  <el-option v-for="lc in locationCode[partStorageLoc.plant_code[key]]" :key="lc.location_code" :value="lc.location_code" :selected="lc.location_code == partStorageLoc.storage_location[key]">{{ lc.location_code }} -
                                    {{ lc.location_description }}</el-option>
                                </el-select>
                              </td>
                              <td>
                                <el-input id="tableSelect" :disabled="isClicked == false" type="text"
                                  :placeholder="tt('bin')" v-model="partStorageLoc.bin_location[key]"
                                  style="min-width:100px"></el-input>
                              </td>
                              <td>
                                <el-input id="tableSelect" :disabled="isClicked == false" type="text"
                                  :placeholder="tt('stock_on_hand')" v-model="partStorageLoc.soh[key]"
                                  style="min-width:150px"></el-input>
                              </td>
                              <td width="10">
                                <i class="fa fa-trash text-danger" @click="deleteStorageLocation(key)"></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <skeleton-loading v-else></skeleton-loading>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- PO TEXT -->
              <div v-else-if="tabs.po_text">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-6">
                            <h4>
                              <b>{{ tt("purchase_order_text") }}</b>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="card-body" style="height:600px;overflow-y: scroll;" v-if="!onLoad.detail">
                        <h5 v-if="dataMaterial.item_name && partPOTEXT.characteristic.length > 0">
                          <span v-if="dataMaterial.item_type_code == 'GEN'">=== {{ dataMaterial.item_name }} ===</span>
                          <span v-if="dataMaterial.item_type_code == 'GEN_OEM'">=== {{ partPOTEXT.value[0] }} ===</span>
                        </h5>
                        <div class="row" v-for="(po, key) in partPOTEXT.cin_code" :key="po.cin_code">
                          <div class="col-6 mb-1">
                            <span style="font-size: 12px">{{ partPOTEXT.characteristic[key] }}</span>
                          </div>
                          <div class="col-6 mb-1">
                            <span style="font-size: 12px">: {{ partPOTEXT.value[key] }}</span>
                          </div>
                        </div>

                        <div
                          v-if="(partEquipment.equipment_code || partMPN.manufacture_code) && dataMaterial.item_type_code == 'OEM'">
                          <div class='row mb-3' v-for="(row, key) in partEquipment.equipment_code" v-if="partEquipment.equipment_code">
                            <h5 class="mt-4">{{ tt('equipment') }}</h5>
                            <span class="col-12" style='font-size: 12px'>S/P FOR {{ partEquipment.equipment_name[key] + ' (' + partEquipment.equipment_code[key] + ')' }}</span>
                            <span class='col-5' style='font-size: 12px'
                              v-if="partEquipment.equipment_name[key]">EQUIPMENT NAME</span>
                            <span class='col-7' style='font-size: 12px' v-if="partEquipment.equipment_name[key]">: {{ partEquipment.equipment_name[key] }}</span>
                            <span class='col-5' style='font-size: 12px' v-if="partEquipment.type[key]">TYPE</span>
                            <span class='col-7' style='font-size: 12px' v-if="partEquipment.type[key]">: {{ partEquipment.type[key] }}</span>
                            <span class='col-5' style='font-size: 12px' v-if="partEquipment.serial_number[key]">SERIAL NUMBER</span>
                            <span class='col-7' style='font-size: 12px' v-if="partEquipment.serial_number[key]">: {{ partEquipment.serial_number[key] }}</span>

                            <span class='col-5' style='font-size: 12px'
                              v-if="partEquipment.manufacturer[key]">MANUFACTURER</span>
                            <span class='col-7' style='font-size: 12px' v-if="partEquipment.manufacturer[key]">: {{partEquipment.manufacturer[key] }}</span>

                            <!-- <span class='col-5' style='font-size: 12px' v-if="partEquipment.additional_data[key]">ADDITIONAL DATA</span>
                              <span class='col-7' style='font-size: 12px' v-if="partEquipment.additional_data[key]">: {{ partEquipment.additional_data[key] }}</span> -->

                          </div>

                          <div v-if="partMPN.manufacture_ref">
                            <h5 class="mt-4">{{ tt('manufacturer_reference') }}</h5>
                            <div class='row mb-3' v-for="(row, key) in partMPN.manufacture_code">
                              <span class='col-5' style='font-size: 12px'
                                v-if="partMPN.manufacture_ref[key]">MANUFACTURE
                                REF</span>
                              <span class='col-7' style='font-size: 12px' v-if="partMPN.manufacture_ref[key]">: {{partMPN.manufacture_ref[0] }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <skeleton-loading v-else></skeleton-loading>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-6">
                            <h4>
                              <b>{{ tt("up_long_description") }}</b>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="card-body" style="height:600px;overflow-y: scroll;" v-if="!onLoad.detail">
                        <span v-for="(row, key) in partLongDescription.long_description" style="font-size: 12px" :key="row.id">
                          <span style="font-size: 12px">{{ partLongDescription.long_description[key]}}</span>
                          <input :disabled="isClicked == false" type="hidden" v-model="partLongDescription.id[key]" />
                        </span>
                      </div>
                      <skeleton-loading v-else></skeleton-loading>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Attachment and Keyword -->
              <div v-else-if="tabs.attachment">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-5">
                            <h4>
                              <b>{{ tt("up_attachment") }}</b>
                            </h4>
                          </div>
                          <div class="col-7 text-right">
                            <base-button size="sm" type="default" @click="smartcatAttachment" :disabled="onLoad.detail">
                              <i class="fa fa-cloud" aria-hidden="true"></i>
                            </base-button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card-body" style="height:600px"> -->
                      <div style="height:600px;overflow-y:scroll">
                        <table class="table table-flush" v-if="!onLoad.detail">
                          <thead class="card-header bg-primary text-white">
                            <tr>
                              <th class="text-white">
                                {{ tt("attachment_document") }}
                                <span class="text-danger">&nbsp;*</span>
                              </th>
                              <th colspan="2" class="text-white">#</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(attachment, key) in partAttachment.file_attachment_name" :key="attachment.file_attachment_name">
                              <td width="400px" class="py-2">
                                <a href="partAttachment.file_attachment_name[key]">{{ partAttachment.file_attachment_name[key] }}</a>
                                <input :disabled="isClicked == false" type="hidden" class="form-control form-control-sm"
                                  :placeholder="tt('attachment_document')" v-model="partAttachment.id[key]" />
                              </td>
                              <td v-if="partAttachment.file_attachment_name[key] != ''" class="py-2">
                                <a :href="partAttachment.file_attachment_name[key]" target="_blank">
                                  <i class="fa fa-eye"></i>
                                </a>
                              </td>
                              <td width="10" class="py-2">
                                <i class="fa fa-trash text-danger" @click="deleteAttachment(key)"></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <skeleton-loading v-else></skeleton-loading>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card">
                      <div class="card-header pb-2">
                        <div class="row">
                          <div class="col-6">
                            <h4>
                              <b>{{ tt("up_keyword") }}</b>
                            </h4>
                          </div>
                          <div class="col-6 text-right">
                            <base-button size="sm" @click="addKeyword()" :disabled="onLoad.detail || !isClicked">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </base-button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="card-body" style="height:600px;"> -->
                      <div style="height:600px;overflow-y:scroll">
                        <table class="table table-flush" v-if="!onLoad.detail">
                          <thead class="card-header bg-primary text-white">
                            <tr>
                              <th class="text-white">
                                {{ tt("keyword") }}
                                <span class="text-danger">&nbsp;*</span>
                              </th>
                              <th class="text-white">#</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(keyword, key) in partKeyword.id" :key="keyword.id">
                              <td width="400px">
                                <el-input id="tableSelect" :disabled="isClicked == false" :placeholder="tt('keyword')"
                                  v-model="partKeyword.keyword[key]"></el-input>
                              </td>
                              <td width="10">
                                <i class="fa fa-trash text-danger mt-2" @click="deleteKeyword(key)"></i>
                                <input :disabled="isClicked == false" v-model="partKeyword.id[key]" type="hidden" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <skeleton-loading v-else></skeleton-loading>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <modal :show.sync="modalSmartcatAttachment" size="lg">
                <template slot="header">
                  <h5 class="modal-title">{{ tt("smartcat_attachment") }}</h5>
                </template>
                <template>
                  <SmartCatAttachment @chooseAttachment="handleChooseAttachment" />
                </template>
              </modal>
            </div>
          </div>
          <div class="card-footer" v-if="!onLoad.table">
            <div class="text-right">
              <el-select :disabled="isClicked == false" v-model="catalog_status"
                :placeholder="tt('choose_catalog_status')">
                <el-option :value="ct" :label="ct" :key="ct" v-for="ct in catalogStatus"></el-option>
              </el-select>

              <base-button @click="save()" :disabled="isClicked == false" class="ml-3">{{ tt("save") }}</base-button>
            </div>
          </div>
        </div>
      </div>
      <!-- Context Menu -->
      <!-- <div v-show="showViewCopy">
                <div class="context" ref="viewCopy"  tabindex="0" @blur="closeContextMenu"  @click="copyModal">
                    {{ tt('copy')}}
                </div>
            </div> -->

      <!-- Modal For Clone Or Copy -->
      <validation-observer>
        <modal :show.sync="showCopyModal">
          <template slot="header">
            <h5 class="modal-title">{{ tt("clone") }}</h5>
            <i class="fa fa-times text-white text-right" @click="showCopyModal = false"></i>
          </template>
          
          <label class="form-control-label">{{tt("material_number_from")}}</label>
          <base-input :name="tt('material_number_from')" rules="required">
            <el-select id="tableSelect" :rows="4" :disabled="isClicked == false" class="select-danger"
              v-model="materialNumberFrom" :placeholder="tt('minimum_type_char', { total: 1 })"
              :remote-method="querySearchMatNum" :loading="selectSearch.matnumFromLoading" filterable remote
              reserve-keyword>
              <el-option class="select-danger" :value="matnum.material_number" :label="matnum.material_number"
                :key="matnum.material_number" v-for="matnum in materialNumber" :selected="matnum.materialNumber"
                ref="materialNumberFrom"></el-option>
            </el-select>
          </base-input>

          <label class="form-control-label">{{tt("material_number_to")}}</label>
          <el-input disabled type="text" :rows="4" v-model="materialNumberTo"></el-input>

          <!-- <template slot="footer"> -->
            <div class="row mt-5">
              <div class="col-5">
                <el-select v-model="catalog_status_clone" placeholder="Catalog Status">
                  <el-option :value="ct" :label="ct" :key="ct" v-for="ct in catalogStatus"></el-option>
                </el-select>
              </div>
              <div class="col-7 text-right">
                <base-button type="primary" @click="cloneData"
                  :disabled="btnClone.onLoading == true || isClicked == false">
                  <span v-if="btnClone.onLoading == true"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait")
                    }}</span>
                  <span v-else>Clone All</span>
                </base-button>
                <template class="catBy">
                  <el-tooltip
                    :content="(cataloguedBy == null || cataloguedBy == '') ? 'Please, save the catalog status first to copy value' : 'Copy value only for the same characteristic'" placement="top">
                    <base-button type="primary" @click="copyData" :disabled="btnCopy.onLoading == true || isClicked == false || cataloguedBy == null || cataloguedBy == ''">
                      <span v-if="btnCopy.onLoading == true"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait")}}</span>
                      <span v-else>{{ tt('copy_value') }}</span>
                    </base-button>
                  </el-tooltip>
                </template>
              </div>
            </div>
          <!-- </template> -->
        </modal>
      </validation-observer>

      <!-- MODAL FOR CheckDuplicateWorkbench -->
      <el-dialog :title="tt('check_duplicate')" :visible.sync="modalCheckDuplicate" lock-scroll fullscreen>
        <CheckDuplicateWorkbench :materialNumber="material_number" :clicked="isClicked"></CheckDuplicateWorkbench>
      </el-dialog>

      <div v-if="oldValue.show == true">
        <div ref="draggableContainer" id="draggable-container">
          <div>
            <div class="card">
              <div class="card-header bg-primary source" id="draggable-header" @mousedown="dragMouseDown">
                <div class="row">
                  <div class="col-10 text-white">
                    {{ partSource.material_number }}
                  </div>
                  <div class="col-2 text-right">
                    <i class="fa fa-times text-white" @click="oldValue.show = false"></i>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div v-if="oldValue.data.length > 0">
                  <div class="row" v-for="value in oldValue.data" :key="value.cinv_code">
                    <div class="col-5">
                      <h5 class="header-source">
                        {{ value.characteristic }}
                      </h5>
                    </div>
                    <div class="col-7">
                      <h5 class="header-source">
                        : {{ value.characteristic_value }}
                      </h5>
                    </div>
                  </div>
                </div>
                <h5 class="text-center bg-light mt-4" v-else>
                  {{ tt("empty") }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <noaccess v-else />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Api from "@/helpers/api";
import WorkbenchMaterial from "@/services/workbench/WorkbenchMaterial.service";
import endUserSearchMaterial from "@/services/endUserSearch/endUserSearchMaterial.service";
import config from "@/configs/config";
import baseApiUrl from "@/configs/config";
import plant from "@/services/master/plantCode.service";
import equipmentCode from "@/services/master/equipmentCode.service";
import locationCode from "@/services/master/locationCode.service";
import standardItemName from "@/services/dictionary/standardItemName.service";
import natoGroupClass from "@/services/dictionary/natoGroupClass.service";
import materialType from "@/services/master/materialType.service";
import manufacture from "@/services/master/manufacture.service";
import sourceType from "@/services/master/sourceType.service";
import manrefType from "@/services/master/manrefType.service";
import ItemType from "@/services/master/ItemType.service";
import drawingMaster from "@/services/dictionary/drawingMaster.service";
import materialGroup from "@/services/master/materialGroup.service";
import catalogType from "@/services/master/catalogType.service";
import catalogStatus from "@/services/master/catalogStatus.service";
import unitOfMeasure from "@/services/dictionary/unitOfMeasure.service";
import characteristicItemName from "@/services/dictionary/characteristicItemName.service";
import companyCode from "@/services/master/companyCode.service";
import StandardItemNameVue from '../Dictionary/StandardItemName.vue';
import Mesc from "@/services/dictionary/mesc.service";

export default {
  watch: {
    "partPlant.plant_code": function () {
      this.dependOnPlant();
    },
    "dataMaterial.inc": function () {
      this.dependOnItemName();
    },
    "dataMaterial.sg_code": function (val) {
      this.dependSegment(val);
    },
    "dataMaterial.fm_code": function (val) {
      this.dependFamily(val)
    },
    "dataMaterial.cl_code": function (val) {
      this.dependClassification(val);
    },
    "dataMaterial.mesc.main": function () {
      this.getSubGroup();
    },
    "dataMaterial.mesc.sub": function () {
      this.getSubSubGroup();
    },
    "mescGroup.mainGroup": function () {
      this.getSubGroup();
    },
    "mescGroup.subGroup": function () {
      this.getSubSubGroup();
    },
    "checked.material_number": function () {
      this.heightTable();
    },
    "checked.new_material_number": function () {
      this.heightTable();
    },
    "checked.item_name": function () {
      this.heightTable();
    },
    "checked.short_description": function () {
      this.heightTable();
    },
    "checked.source_description": function () {
      this.heightTable();
    },
    "checked.uom": function () {
      this.heightTable();
    },
    "checked.material_type": function () {
      this.heightTable();
    },
    "checked.material_group": function () {
      this.heightTable();
    },
    "checked.cat_type": function () {
      this.heightTable();
    },
    "checked.cat_status": function () {
      this.heightTable();
    },
    "checked.status_report": function () {
      this.heightTable();
    },
    "checked.catalogued_by": function () {
      this.heightTable();
    },
    "checked.level": function () {
      this.heightTable();
    },
    "checked.short_length": function () {
      this.heightTable();
    },
    // "checked.priority": function() {
    //   this.heightTable();
    // }
  },
  data() {
    return {
      positions: {
        clientX: 100,
        clientY: 100,
        movementX: 0,
        movementY: 0
      },
      refreshData: false,
      showViewCopy: false,
      showCopyModal: false,
      top: "0px",
      left: "0px",
      currentRow: null,
      loadTimeout: "",
      params: this.$route.params,
      checked: {
        material_number: true,
        new_material_number: true,
        item_name: true,
        short_description: true,
        source_description: true,
        uom: true,
        material_type: false,
        material_group: false,
        cat_type: true,
        cat_status: true,
        status_report: true,
        // priority: true,
        catalogued_by: true,
        short_length: true,
        short_length_2: false,
        short_description_2: false,
      },
      tabs: {
        basic_data: false,
        mpn: false,
        po_text: false,
        plant: false,
        sales: false,
        mrp: false,
        accounting: false,
        purchasing: false,
        equipment: false,
        attachment: false,
        keyword: false,
        mcr: false,
        source: false
      },
      showSource: false,
      onLoad: {
        table: true,
        detail: true,
        classification: true,
      },
      btnShowCharValue: {
        onLoading: false
      },
      btnClone: {
        onLoading: false
      },
      btnCopy: {
        onLoading: false
      },
      table: {
        search: "",
        total: 0,
        data: [],
        page: {
          current_page: 1
        }
      },
      dataMaterial: {
        id: "",
        inc: "",
        item_name: "",
        group_class: "",
        material_type: "",
        item_type: "",
        material_group: "",
        catalog_type: "",
        scope_of_supply: "",
        new_material_number: "",
        fab_non_fab: "",
        uom: "",
        bom_non_bom: "",
        critical_non_critical: "",
        po_text_memo: "",
        note: "",
        mcr_number: "",
        group_class_description: "",
        material_type_description: "",
        material_group_description: "",
        catalog_type_description: "",
        material_number: "",
        sg_code: "",
        fm_code: "",
        cl_code: "",
        cm_code: "",
        config: "",
        mesc: {
          main: "",
          sub: "",
          subSub: ""
        }
      },
      selectSearch: {
        sitLoading: false,
        ngcLoading: false,
        uomLoading: false,
        mtLoading: false,
        ctLoading: false,
        mgLoading: false,
        sosLoading: false,
        mancodeLoading: false,
        plantLoading: false,
        slocLoading: false,
        binlocLoading: false,
        pcenterLoading: false,
        mrpControllerLoading: false,
        spcProcTypeLoading: false,
        valClassLoading: false,
        purGroupLoading: false,
        equipmentLoading: false,
        drawingNumLoading: false,
        matnumLoading: false,
        orderUnitLoading: false,
        companyLoading: false,
        matnumFromLoading: false,
        loadTimeout: null,
        unspsc: false,
      },
      paramExport: [],
      detailMaterial: {},
      storageUrl: config.storageUrl,
      apiUrl: baseApiUrl.apiUrl,
      token: localStorage.token,
      modalEdit: {
        mpn: false
      },
      modalSmartcatAttachment: false,
      modalCheckDuplicate: false,
      mpn: {},
      locationCode: {},
      masterManufacture: {},
      standardItemName: {},
      natoGroupClass: {},
      materialType: {},
      materialGroup: {},
      itemType: {},
      manrefType: {},
      fabNonFab: {},
      bomNonBom: {},
      criticalNonCritical: {},
      sourceType: {},
      equipmentCode: {},
      catalogType: {},
      drawingMaster: {},
      materialNumber: [],
      unitOfMeasure: {},
      material_number: "",
      plantCode: [],
      companyCode: [],
      plantList: {},
      equipmentList: {},
      input: [],
      partPlant: {
        id: [],
        plant_code: [],
        company_code: []
      },
      partStorageLoc: {
        id: [],
        plant_code: [],
        storage_location: [],
        bin_location: [],
        soh: []
      },
      partMPN: {
        part_manufacture_ref_id: [],
        manufacture_code: [],
        manufacture_ref: [],
        manufacture_type: [],
        manufacture_note: [],
        source_type_code: [],
        shortdes: []
      },
      partEquipment: {
        id: [],
        plant_code: [],
        equipment_code: [],
        qty_installed: [],
        drawing_number: [],
        equipment_name: [],
        type: [],
        serial_number: [],
        manufacturer: [],
        additional_data: [],
        shortdes: []
      },
      partPOTEXT: {
        cin_code: [],
        characteristic: [],
        value: [],
        view: [],
        code: []
      },
      partLongDescription: {
        id: [],
        long_description: []
      },
      partKeyword: {
        id: [],
        keyword: []
      },
      partAttachment: {
        id: [],
        file_attachment_name: []
      },
      partCharValue: {
        id: [],
        cin_code: [],
        characteristic: [],
        value: [],
        view: [],
        multipleChar: [],
        code: [],
        mandatory: []
      },
      partSource: {},
      characteristicItemName: {},
      charValue: [],
      partCharShow: false,
      catalogStatus: {},
      catalog_status: "",
      isClicked: false,
      dataClicked: {},
      multipleChangeCatStatus: [],
      currentRow: null,
      materialNumberTo: "",
      cataloguedBy: null,
      materialNumberFrom: "",
      catalog_status_clone: "",
      // Menggunakan mescGroup untuk list Data MESC GROUP
      mescGroup: {
        mainGroup: [],
        subGroup: [],
        subSubGroup: []
      },
      searchMescType: "Main Group",
      requestCalls: 0,
      oldValue: {
        show: false,
        data: []
      },
      segmentCodes: [],
      familyCodes: [],
      classificationCodes: [],
      commodityCodes: [],
      checkedColumn: {},
    };
  },
  computed: {
    ...mapState({
      authUserPermission: state => state.auth.userPermission,
    }),
    ...mapState({
      authUserConfig: state => state.auth.userConfig,
    }),
    configCodification() {
      if (this.dataMaterial.config != "") {
        let configs = JSON.parse(this.dataMaterial.config)
        return configs.codification
      } else {
        return ""
      }
    },
    configBasicData() {
      if (this.dataMaterial.config != "") {
        let configs = JSON.parse(this.dataMaterial.config)
        return configs.basicData.split(',')
      }
    },
  },
  mounted() {
    this.tab("basic_data");
    this.get();
    this.getParam();
    this.getPlant();
    this.getEquipmentCode();
    this.getSourceType();
    this.getManrefType();
    this.getItemType();
    this.getCatalogStatus();
    this.configWorkbenchTable();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (this.currentRow != null) {
        if (this.currentRow.material_number == row.material_number) {
          return "current-row";
        }
      }
    },
    get() {
      let context = this;
      if (this.$route.query.type == "search-material") {
        Api(
          context,
          WorkbenchMaterial.endUserWorkBenchMaterial(
            this.table.page.current_page,
            this.$route.query
          )
        )
          .onSuccess(function (response) {
            context.table.total = response.data.data.workbench.total;
            context.table.data = response.data.data.workbench.data;
            context.table.page = response.data.data.workbench;
            context.onLoad.table = false;
            context.onLoad.detail = false;
            context.$forceUpdate()
          })
          .onError(function (error) {
            context.table.data = [];
            context.table.total = 0;
            context.onLoad.table = false;
            context.onLoad.detail = false;
          }).call();
      } else {
        Api(
          context,
          WorkbenchMaterial.searchByCharacteristic(
            this.table.page.current_page,
            this.$route.query
          )
        )
          .onSuccess(function (response) {
            context.table.total = response.data.data.workbench.total;
            context.table.data = response.data.data.workbench.data;
            context.table.page = response.data.data.workbench;
            context.onLoad.table = false;
            context.onLoad.detail = false;
          })
          .onError(function (error) {
            context.table.data = [];
            context.table.total = 0;
            context.onLoad.table = false;
            context.onLoad.detail = false;
          })
          .call();
      }
    },
    getParam() {
      var a = this.$route.query;
      var url = Object.keys(a)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(a[k]);
        })
        .join("&");
      this.paramExport = url;
    },
    handleCurrentChange(val) {
      let context = this;
      this.onLoad.detail = true;
      if (val != null) {
        context.currentRow = val;
        context.material_number = context.currentRow.material_number;
        context.materialNumberTo = context.currentRow.material_number;
        context.cataloguedBy = context.currentRow.catalogued_by;
      }

      Api(
        context,
        WorkbenchMaterial.workBenchMaterial({
          material_number: context.currentRow.material_number
        })
      )
        .onSuccess(function (response) {
          context.isClicked = response.data.data.clicked_permission;
          context.dataClicked = response.data.data.clicked_data;
          context.catalog_status = response.data.data.part_master[0].cat_status_code
          // DEVI GET PLANT
          context.partPlant.id = [];
          context.partPlant.plant_code = [];
          context.partPlant.company_code = [];
          for (var i = 0; i < response.data.data.part_plant.length; i++) {
            context.partPlant.id[i] =
              response.data.data.part_plant[i].id == null
                ? ""
                : response.data.data.part_plant[i].id;
            context.partPlant.plant_code[i] =
              response.data.data.part_plant[i].plant_code == null
                ? ""
                : response.data.data.part_plant[i].plant_code;
            context.partPlant.company_code[i] =
              response.data.data.part_plant[i].company_code == null
                ? ""
                : response.data.data.part_plant[i].company_code;
          }

          // DEVI GET STORAGE LOCATION
          context.partStorageLoc.id = [];
          context.partStorageLoc.plant_code = [];
          context.partStorageLoc.storage_location = [];
          context.partStorageLoc.bin_location = [];
          context.partStorageLoc.soh = [];
          for (
            var i = 0;
            i < response.data.data.part_storage_location.length;
            i++
          ) {
            context.partStorageLoc.id[i] = response.data.data.part_storage_location[i].id == null ? "" : response.data.data.part_storage_location[i].id;
            context.partStorageLoc.plant_code[i] = response.data.data.part_storage_location[i].plant_code == null ? "" : response.data.data.part_storage_location[i].plant_code;
            context.partStorageLoc.storage_location[i] = response.data.data.part_storage_location[i].location_code == null ? "" : response.data.data.part_storage_location[i].location_code;
            context.partStorageLoc.bin_location[i] = response.data.data.part_storage_location[i].bin_code == null ? "" : response.data.data.part_storage_location[i].bin_code;
            context.partStorageLoc.soh[i] = response.data.data.part_storage_location[i].stock_on_hand == null ? "" : response.data.data.part_storage_location[i].stock_on_hand;
          }
          // DEVI GET MPN
          context.partMPN.manufacture_code = [];
          context.partMPN.manufacture_ref = [];
          context.partMPN.manufacture_type = [];
          context.partMPN.manufacture_note = [];
          context.partMPN.source_type_code = [];
          context.partMPN.part_manufacture_ref_id = [];
          // cpntext.partMPN.shortdes = []
          for (
            var i = 0;
            i < response.data.data.part_manufacture_ref.length;
            i++
          ) {
            context.partMPN.manufacture_code[i] = response.data.data.part_manufacture_ref[i].manufacture_code == null ? "" : response.data.data.part_manufacture_ref[i].manufacture_code;
            context.partMPN.manufacture_ref[i] = response.data.data.part_manufacture_ref[i].manufacture_ref == null ? "" : response.data.data.part_manufacture_ref[i].manufacture_ref;
            context.partMPN.manufacture_type[i] = response.data.data.part_manufacture_ref[i].type == null ? "" : response.data.data.part_manufacture_ref[i].type;
            context.partMPN.manufacture_note[i] = response.data.data.part_manufacture_ref[i].note == null ? "" : response.data.data.part_manufacture_ref[i].note;
            context.partMPN.source_type_code[i] = response.data.data.part_manufacture_ref[i].source_type_code == null ? "" : response.data.data.part_manufacture_ref[i].source_type_code;
            context.partMPN.part_manufacture_ref_id[i] = response.data.data.part_manufacture_ref[i].id == null ? "" : response.data.data.part_manufacture_ref[i].id;
            context.partMPN.shortdes[i] = response.data.data.part_manufacture_ref[i].shortdes;
          }

          context.partEquipment.id = []
          context.partEquipment.plant_code = []
          context.partEquipment.equipment_code = []
          context.partEquipment.qty_installed = []
          context.partEquipment.drawing_number = []
          context.partEquipment.equipment_name = []
          context.partEquipment.type = []
          context.partEquipment.serial_number = []
          context.partEquipment.manufacturer = []
          context.partEquipment.additional_data = []
          context.partEquipment.shortdes = []
          for (
            var i = 0;
            i < response.data.data.part_equipment_code.length;
            i++
          ) {
            context.partEquipment.id[i] = response.data.data.part_equipment_code[i].id == null ? "" : response.data.data.part_equipment_code[i].id;
            context.partEquipment.plant_code[i] = response.data.data.part_equipment_code[i].plant_code == null ? "" : response.data.data.part_equipment_code[i].plant_code;
            context.partEquipment.equipment_code[i] = response.data.data.part_equipment_code[i].equipment_code == null ? "" : response.data.data.part_equipment_code[i].equipment_code;
            context.partEquipment.qty_installed[i] = response.data.data.part_equipment_code[i].qty_installed == null ? "" : response.data.data.part_equipment_code[i].qty_installed;
            context.partEquipment.drawing_number[i] = response.data.data.part_equipment_code[i].drawing_number == null ? "" : response.data.data.part_equipment_code[i].drawing_number;
            context.partEquipment.equipment_name[i] = response.data.data.part_equipment_code[i].equipment_name == null ? "" : response.data.data.part_equipment_code[i].equipment_name;
            context.partEquipment.type[i] = response.data.data.part_equipment_code[i].type == null ? "" : response.data.data.part_equipment_code[i].type;
            context.partEquipment.serial_number[i] = response.data.data.part_equipment_code[i].serial_number == null ? "" : response.data.data.part_equipment_code[i].serial_number;
            context.partEquipment.manufacturer[i] = response.data.data.part_equipment_code[i].manufacturer == null ? "" : response.data.data.part_equipment_code[i].manufacturer;
            context.partEquipment.additional_data[i] = response.data.data.part_equipment_code[i].additional_data == null ? "" : response.data.data.part_equipment_code[i].additional_data;
            context.partEquipment.shortdes[i] = response.data.data.part_equipment_code[i].shortdes;
          }
          // DEVI GET PO_TEXT

          context.partPOTEXT.cin_code = [];
          context.partPOTEXT.characteristic = [];
          context.partPOTEXT.value = [];
          context.partPOTEXT.view = [];
          context.partPOTEXT.code = [];

          for (var i = 0; i < response.data.data.po_text.length; i++) {
            context.partPOTEXT.cin_code[i]       = response.data.data.po_text[i].cin_code == null ? "" : response.data.data.po_text[i].cin_code;
            context.partPOTEXT.characteristic[i] = response.data.data.po_text[i].characteristic == null ? "" : response.data.data.po_text[i].characteristic;
            context.partPOTEXT.value[i]          = response.data.data.po_text[i].characteristic_value == null ? "" : response.data.data.po_text[i].characteristic_value;
            context.partPOTEXT.view[i]           = response.data.data.po_text[i].view == "checked" ? true : false;
            context.partPOTEXT.code[i]           = response.data.data.po_text[i].code == null ? "" : response.data.data.po_text[i].code;
          }

          // Devi Get Long Description
          context.partLongDescription.id = [];
          context.partLongDescription.long_description = [];
          for (var i = 0; i < response.data.data.long_description.length; i++) {
            context.partLongDescription.long_description[i] = response.data.data.long_description[i].long_description == null ? "" : response.data.data.long_description[i].long_description;
            context.partLongDescription.id[i]               = response.data.data.long_description[i].id == null ? "" : response.data.data.long_description[i].id;
          }

          // Devi Get Keyword
          context.partKeyword.id = [];
          context.partKeyword.keyword = [];
          for (var i = 0; i < response.data.data.part_keyword.length; i++) {
            context.partKeyword.id[i]      = response.data.data.part_keyword[i].id == null ? "" : response.data.data.part_keyword[i].id;
            context.partKeyword.keyword[i] = response.data.data.part_keyword[i].keyword == null ? "" : response.data.data.part_keyword[i].keyword;
          }

          context.partAttachment.id = [];
          context.partAttachment.file_attachment_name = [];
          for (var i = 0; i < response.data.data.part_attachment.length; i++) {
            context.partAttachment.id[i]                   = response.data.data.part_attachment[i].id == null ? "" : response.data.data.part_attachment[i].id;
            context.partAttachment.file_attachment_name[i] = response.data.data.part_attachment[i].file_attachment_code == null ? "" : response.data.data.part_attachment[i].file_attachment_code;
          }

          // Get Part Char
          context.partCharValue.id = [];
          context.partCharValue.cin_code = [];
          context.partCharValue.characteristic = [];
          context.partCharValue.value = [];
          context.partCharValue.view = [];
          context.partCharValue.multipleChar = [];
          context.partCharValue.code = [];
          context.partCharValue.mandatory = [];
          for (
            var i = 0;
            i < response.data.data.data_part_char_value.length;
            i++
          ) {
            context.partCharValue.id[i]             = response.data.data.data_part_char_value[i].id == null ? "" : response.data.data.data_part_char_value[i].id;
            context.partCharValue.cin_code[i]       = response.data.data.data_part_char_value[i].cin_code == null ? "" : response.data.data.data_part_char_value[i].cin_code;
            context.partCharValue.characteristic[i] = response.data.data.data_part_char_value[i].characteristic == null ? "" : response.data.data.data_part_char_value[i].characteristic;
            context.partCharValue.value[i]          = response.data.data.data_part_char_value[i].characteristic_value == null ? "" : response.data.data.data_part_char_value[i].characteristic_value;
            context.partCharValue.view[i]           = response.data.data.data_part_char_value[i].view == "checked" ? true : false;
            context.partCharValue.mandatory[i]      = response.data.data.data_part_char_value[i].mandatory ? response.data.data.data_part_char_value[i].mandatory : false;
            context.partCharValue.multipleChar[i]   = response.data.data.data_part_char_value[i].single_or_multiple == null ? "" : response.data.data.data_part_char_value[i].single_or_multiple;
            context.partCharValue.code[i]           = response.data.data.data_part_char_value[i].code == null ? "" : response.data.data.data_part_char_value[i].code;
          }

          context.partSource = response.data.data.part_source;
          context.fabNonFab = response.data.data.fabrication_non_fabrication;
          context.bomNonBom = response.data.data.bom_non_bom;
          context.criticalNonCritical = response.data.data.critical_non_critical;
        
          var part_master = response.data.data.part_master[0];
          context.dataMaterial.id = part_master.id == null ? "" : part_master.id;
          context.dataMaterial.inc = part_master.inc == null ? "" : part_master.inc + " - " + part_master.item_name;
          context.dataMaterial.item_name = part_master.item_name == null ? "" : part_master.item_name;
          context.dataMaterial.group_class = part_master.nsc == null ? "" : part_master.nsc + " - " + part_master.nsc_description;
          context.dataMaterial.group_class_description = part_master.nsc_description == null ? "" : part_master.nsc_description;
          context.dataMaterial.material_type = part_master.material_type == null ? "" : part_master.material_type + " - " + part_master.material_type_description;
          context.dataMaterial.material_type_description = part_master.material_type_description == null ? "" : part_master.material_type_description;
          context.dataMaterial.material_group = part_master.material_group == null ? "" : part_master.material_group + " - " + part_master.material_group_description;
          context.dataMaterial.material_group_description = part_master.material_group_description == null ? "" : part_master.material_group_description;
          context.dataMaterial.catalog_type = part_master.cat_type == null ? "" : part_master.cat_type + " - " + part_master.cat_type_description;
          context.dataMaterial.catalog_type_description = part_master.cat_type_description == null ? "" : part_master.cat_type_description;
          context.dataMaterial.item_type = part_master.item_type_code == null ? "" : part_master.item_type_code + " - " + part_master.item_type_description;
          context.dataMaterial.item_type_code = part_master.item_type_code == null ? "" : part_master.item_type_code;
          // context.dataMaterial.item_type_code =
          //   part_master.item_type_description == null
          //     ? ""
          //     : part_master.item_type_description;
          context.dataMaterial.scope_of_supply       = part_master.scope_of_supply_code == null ? "" : part_master.scope_of_supply_code;
          context.dataMaterial.uom                   = part_master.uom == null ? "" : part_master.uom + " - " + part_master.uom_description;
          context.dataMaterial.uom_description       = part_master.uom_description == null ? "" : part_master.uom_description;
          context.dataMaterial.fab_non_fab           = part_master.fab_non_fab == null ? "" : part_master.fab_non_fab;
          context.dataMaterial.bom_non_bom           = part_master.bom_non_bom == null ? "" : part_master.bom_non_bom;
          context.dataMaterial.new_material_number   = part_master.new_material_number == null ? "" : part_master.new_material_number;
          context.dataMaterial.critical_non_critical = part_master.critical_non_critical == null ? "" : part_master.critical_non_critical;
          context.dataMaterial.po_text_memo          = part_master.po_text_memo == null ? "" : part_master.po_text_memo;
          context.dataMaterial.note                  = part_master.note == null ? "" : part_master.note;
          context.dataMaterial.material_number       = part_master.material_number == null ? "" : part_master.material_number;
          context.material_number                    = context.dataMaterial.material_number;
          context.dataMaterial.sg_code               = response.data.data.part_unspsc != null ? response.data.data.part_unspsc.sg_code : "";
          context.dataMaterial.fm_code               = response.data.data.part_unspsc != null ? response.data.data.part_unspsc.fm_code : "";
          context.dataMaterial.cl_code               = response.data.data.part_unspsc != null ? response.data.data.part_unspsc.cl_code : "";
          context.dataMaterial.cm_code               = response.data.data.part_unspsc != null ? response.data.data.part_unspsc.cm_code : "";
          context.dataMaterial.config                = part_master.config == null ? "" : part_master.config;
          var mesc_group                             = response.data.data.mesc_group;
          context.dataMaterial.mesc.main             = mesc_group.main == null ? "" : mesc_group.main;
          context.dataMaterial.mesc.sub              = mesc_group.sub == null ? "" : mesc_group.sub;
          context.dataMaterial.mesc.subSub           = mesc_group.subSub == null ? "" : mesc_group.subSub;
        })
        .onError(function (error) {
          context.detailMaterial = {};
          context.partMPN = [];
          context.partPlant = [];
          context.partPOTEXT = [];
          context.partStorageLoc = [];
          context.partEquipment = [];
          context.partLongDescription = [];
          context.partSource = {};
          context.partAttachment = [];
          context.partKeyword = [];
        })
        .onFinish(function () {
          if (context.dataClicked != null) {
            context.$notify({
              message: context.tt("handle_by_another"),
              type: "danger",
              verticalAlign: "bottom",
              horizontalAlign: "left"
            });
          }
          context.oldValue.data = []
          context.onLoad.detail = false;
        })
        .call();
    },
    handleSelectionChange(val) {
      let context = this;
      if (val.length == 0) {
        context.multipleChangeCatStatus = []
      } else {
        val.forEach(function (element) {
          context.multipleChangeCatStatus.push(element.material_number);
        });
      }

    },
    handleDataMaterial(type, val) {
      if (type == "inc") {
        this.datamaterial.inc = val;
      } else if (type == "mat_type") {
        this.dataMaterial.material_type = val;
      } else if (type == "group_class") {
        this.dataMaterial.group_class = val;
      } else if (type == "cat_type") {
        this.dataMaterial.catalog_type = val;
      } else if (type == "uom") {
        this.dataMaterial.uom = val;
      } else if (type == "mat_group") {
        this.dataMaterial.material_group = val;
      }
    },
    getPlant() {
      let context = this;
      Api(context, plant.get(null, { per_page: "none" }))
        .onSuccess(function (response) {
          response.data.data.data.data.forEach(function (item, index) {
            context.plantCode[index] = {
              value: item["plant_code"],
              label: item["plant_code"] + " - " + item["plant_description"]
            };
          });
        })
        .onError(function (error) {
          if (error.response.status == 404) {
            context.plantCode = [];
          }
        })
        .call();
    },
    getEquipmentCode() {
      let context = this;
      Api(context, equipmentCode.get(null, { per_page: "none" }))
        .onSuccess(function (response) {
          response.data.data.data.data.forEach(function (item, index) {
            context.equipmentCode[index] = {
              value: item["equipment_code"],
              label:
                item["equipment_code"] + " - " + item["equipment_description"]
            };
          });
        })
        .onError(function (error) {
          if (error.response.status == 404) {
            context.equipmentCode = [];
          }
        })
        .call();
    },
    getSourceType() {
      let context = this;
      Api(context, sourceType.get(null, { per_page: "none" }))
        .onSuccess(function (response) {
          context.sourceType = response.data.data.data.data;
        })
        .onError(function (error) {
          if (error.response.status == 404) {
            context.sourceType = [];
          }
        })
        .call();
    },
    getManrefType() {
      let context = this;
      Api(context, manrefType.get(null, { per_page: "none" }))
        .onSuccess(function (response) {
          context.manrefType = response.data.data.data.data;
        })
        .onError(function (error) {
          if (error.response.status == 404) {
            context.manrefType = [];
          }
        })
        .call();
    },
    tab(type) {
      if (type == "basic_data") {
        this.tabs.basic_data = true;
        this.tabs.mpn = false;
        this.tabs.po_text = false;
        this.tabs.plant = false;
        this.tabs.attachment = false;
      } else if (type == "mpn") {
        this.tabs.basic_data = false;
        this.tabs.mpn = true;
        this.tabs.po_text = false;
        this.tabs.plant = false;
        this.tabs.attachment = false;
      } else if (type == "po_text") {
        this.tabs.basic_data = false;
        this.tabs.mpn = false;
        this.tabs.po_text = true;
        this.tabs.plant = false;
        this.tabs.attachment = false;
      } else if (type == "plant") {
        this.tabs.basic_data = false;
        this.tabs.mpn = false;
        this.tabs.po_text = false;
        this.tabs.plant = true;
        this.tabs.attachment = false;
      } else if (type == "attachment") {
        this.tabs.basic_data = false;
        this.tabs.mpn = false;
        this.tabs.po_text = false;
        this.tabs.plant = false;
        this.tabs.attachment = true;
      } else if (type == "source") {
        this.showSource = true;
      }
    },
    modal(
      session,
      manufacture_code,
      source_type,
      manufacture_ref,
      type,
      notes
    ) {
      if (session == "mpn") {
        this.modalEdit.mpn = true;
        this.mpn.manufacture_code = manufacture_code;
        this.mpn.manufacture_ref = manufacture_ref;
        this.mpn.source_type = source_type;
        this.mpn.type = type;
        this.mpn.notes = notes;
      }
    },
    changePage(page) {
      let context = this;
      context.onLoad.table = true;
      context.onLoad.detail = true;
      clearTimeout(this.loadTimeout);
      this.loadTimeout = setTimeout(() => {
        this.get();
      }, 100);
    },
    dragMouseDown: function (event) {
      event.preventDefault();
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        "px";
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        "px";
    },
    closeDragElement() {
      document.onmouseup = "";
      document.onmousemove = "";
    },
    addPlant() {
      if (this.partPlant.plant_code.length == 0) {
        this.partPlant.id.push("");
        this.partPlant.plant_code.push("");
        this.partPlant.company_code.push("");
      } else if (this.partPlant.plant_code.length > 0) {
        if (
          this.partPlant.plant_code[this.partPlant.plant_code.length - 1] != ""
        ) {
          this.partPlant.id.push("");
          this.partPlant.plant_code.push("");
          this.partPlant.company_code.push("");
        } else {
          this.completeDataNotify("plant");
        }
      }
    },
    deletePlant(key) {
      this.confirmDialog(this.tt("confirm_delete")).then(result => {
        if (result.value) {
          this.partPlant.id.splice(key, 1);
          this.partPlant.plant_code.splice(key, 1);
          this.partPlant.company_code.splice(key, 1);
        }
      });
    },
    addStorageLocation() {
      if (this.partStorageLoc.plant_code.length == 0) {
        this.partStorageLoc.id.push("");
        this.partStorageLoc.plant_code.push("");
        this.partStorageLoc.storage_location.push("");
        this.partStorageLoc.bin_location.push("");
        this.partStorageLoc.soh.push("");
      } else if (this.partStorageLoc.plant_code.length > 0) {
        if (
          this.partStorageLoc.plant_code[
          this.partStorageLoc.plant_code.length - 1
          ] != "" &&
          this.partStorageLoc.storage_location[
          this.partStorageLoc.storage_location.length - 1
          ] != ""
        ) {
          this.partStorageLoc.id.push("");
          this.partStorageLoc.plant_code.push("");
          this.partStorageLoc.storage_location.push("");
          this.partStorageLoc.soh.push("");
        } else {
          this.completeDataNotify("storage_location");
        }
      }
    },
    deleteStorageLocation(key) {
      this.confirmDialog(this.tt("confirm_delete")).then(result => {
        if (result.value) {
          this.partStorageLoc.id.splice(key, 1);
          this.partStorageLoc.plant_code.splice(key, 1);
          this.partStorageLoc.storage_location.splice(key, 1);
          this.partStorageLoc.bin_location.splice(key, 1);
          this.partStorageLoc.soh.splice(key, 1);
        }
      });
    },
    addMpn() {
      if (
        Object.entries(this.masterManufacture).length === 0 ||
        Object.entries(this.manrefType).length === 0
      ) {
        this.getManrefType();
        this.getSourceType();
      }
      if (this.partMPN.manufacture_code.length == 0) {
        this.partMPN.manufacture_code.push("");
        this.partMPN.manufacture_ref.push("");
        this.partMPN.manufacture_type.push("");
        this.partMPN.manufacture_note.push("");
        this.partMPN.source_type_code.push("");
        this.partMPN.part_manufacture_ref_id.push("");
        this.partMPN.shortdes.push(false);
      } else if (this.partMPN.manufacture_code.length > 0) {
        if (
          this.partMPN.manufacture_code[
          this.partMPN.manufacture_code.length - 1
          ] != "" &&
          this.partMPN.source_type_code[
          this.partMPN.source_type_code.length - 1
          ] != "" &&
          this.partMPN.manufacture_type[
          this.partMPN.manufacture_type.length - 1
          ] != ""
        ) {
          this.partMPN.manufacture_code.push("");
          this.partMPN.source_type_code.push("");
          this.partMPN.manufacture_type.push("");
          this.partMPN.manufacture_note.push("");
          this.partMPN.manufacture_ref.push("");
          this.partMPN.part_manufacture_ref_id.push("");
          this.partMPN.shortdes.push(false);
        } else {
          this.completeDataNotify("mpn");
        }
      }
    },
    deleteMpn(key) {
      this.confirmDialog(this.tt("confirm_delete")).then(result => {
        if (result.value) {
          this.partMPN.manufacture_code.splice(key, 1);
          this.partMPN.manufacture_ref.splice(key, 1);
          this.partMPN.source_type_code.splice(key, 1);
          this.partMPN.manufacture_type.splice(key, 1);
          this.partMPN.manufacture_note.splice(key, 1);
          this.partMPN.shordes.splice(key, 1);
        }
      });
    },
    addEquipment() {
      if (this.partEquipment.plant_code.length == 0) {
        this.partEquipment.id.push("");
        this.partEquipment.plant_code.push("");
        this.partEquipment.equipment_code.push("");
        this.partEquipment.qty_installed.push("");
        this.partEquipment.drawing_number.push("");
        this.partEquipment.shortdes.push(false);
      } else if (this.partEquipment.plant_code.length > 0) {
        if (
          this.partEquipment.plant_code[
          this.partEquipment.plant_code.length - 1
          ] != "" &&
          this.partEquipment.equipment_code[
          this.partEquipment.equipment_code.length - 1
          ] != "" &&
          // this.partEquipment.qty_installed[this.partEquipment.qty_installed.length-1] != '' &&
          this.partEquipment.drawing_number[
          this.partEquipment.drawing_number.length - 1
          ] != ""
        ) {
          this.partEquipment.id.push("");
          this.partEquipment.plant_code.push("");
          this.partEquipment.equipment_code.push("");
          this.partEquipment.qty_installed.push("");
          this.partEquipment.drawing_number.push("");
          this.partEquipment.shortdes.push(false);
        } else {
          this.completeDataNotify("equipment");
        }
      }
    },
    deleteEquipment(key) {
      this.confirmDialog(this.tt("confirm_delete")).then(result => {
        if (result.value) {
          this.partEquipment.plant_code.splice(key, 1);
          this.partEquipment.equipment_code.splice(key, 1);
          this.partEquipment.qty_installed.splice(key, 1);
          this.partEquipment.drawing_number.splice(key, 1);
          this.partEquipment.shortdes.splice(key, 1);
        }
      });
    },
    deleteAttachment(key) {
      this.confirmDialog(this.tt("confirm_delete")).then(result => {
        if (result.value) {
          this.partAttachment.file_attachment_name.splice(key, 1);
          this.partAttachment.id.splice(key, 1);
        }
      });
    },
    addKeyword() {
      if (this.partKeyword.keyword.length == 0) {
        this.partKeyword.id.push("");
        this.partKeyword.keyword.push("");
      } else if (this.partKeyword.keyword.length > 0) {
        if (
          this.partKeyword.keyword[this.partKeyword.keyword.length - 1] != ""
        ) {
          this.partKeyword.id.push("");
          this.partKeyword.keyword.push("");
        } else {
          this.completeDataNotify("keyword");
        }
      }
    },
    deleteKeyword(key) {
      this.confirmDialog(this.tt("confirm_delete")).then(result => {
        if (result.value) {
          this.partKeyword.id.splice(key, 1);
          this.partKeyword.keyword.splice(key, 1);
        }
      });
    },
    addPO() {
      var po_text = {
        characteristic: "",
        characteristic_value: ""
      };
      this.po_text.push(po_text);
    },
    deletePO(key) {
      this.confirmDialog(this.tt("confirm_delete")).then(result => {
        if (result.value) {
          this.po_text.splice(key, 1);
        }
      });
    },
    addLongDescription() {
      if (this.partLongDescription.long_description.length == 0) {
        this.partLongDescription.id.push("");
        this.partLongDescription.long_description.push("");
      } else if (this.partLongDescription.long_description.length > 0) {
        if (
          this.partLongDescription.long_description[
          this.partLongDescription.long_description.length - 1
          ] != ""
        ) {
          this.partLongDescription.id.push("");
          this.partLongDescription.long_description.push("");
        } else {
          this.completeDataNotify("long_description");
        }
      }
    },
    deleteLongDescription(key) {
      this.confirmDialog(this.tt("confirm_delete")).then(result => {
        if (result.value) {
          this.partLongDescription.id.splice(key, 1);
          this.partLongDescription.long_description.splice(key, 1);
        }
      });
    },

    completeDataNotify(title) {
      this.$notify({
        message: this.tt("please_complete_data", { title: this.tt(title) }),
        type: "danger",
        verticalAlign: "bottom",
        horizontalAlign: "left"
      });
    },
    dependOnPlant() {
      if (this.partPlant.plant_code.length > 0) {
        if (this.partPlant.plant_code[0] != "") {
          let context = this;
          // GET LOCATION
          context.partPlant.plant_code.forEach(function (item) {
            Api(context, locationCode.getByPlant(item))
              .onSuccess(function (response) {
                context.locationCode[item] = response.data.data;
              })
              .call();
          });
        }
      }
    },
    dependOnItemName() {
      let context = this;
      this.mescGroup.mainGroup = [];
      this.mescGroup.subGroup = [];
      this.mescGroup.subSubGroup = [];
      if (this.dataMaterial.inc != "") {
        let splitInc = this.dataMaterial.inc.split(" - ");
        context.selectSearch.ngcLoading = true;
        context.selectSearch.mtLoading = true;
        context.selectSearch.mgLoading = true;
        context.selectSearch.unspsc = true;
        Api(context, standardItemName.getByItemName(splitInc[0]))
          .onSuccess(function (response) {
            context.materialGroup = response.data.data.data_material_group;
            context.materialType = response.data.data.data_material_type;
            context.natoGroupClass = response.data.data.data_group_class;
            context.segmentCodes = response.data.data.data_unspsc;
            context.mescGroup.mainGroup = response.data.data.data_main_group_mesc;
            // Check Length Material Group
            if (context.materialGroup.length == 1) {
              context.dataMaterial.material_group = context.materialGroup[0].code + " - " + context.materialGroup[0].description;
            } else if (context.materialGroup.length == 0) {
              context.dataMaterial.material_group = "";
            } else {
              let found = false;
              for (var i = 0; i < context.materialGroup.length; i++) {
                if (context.dataMaterial.material_group === context.materialGroup[i].code + " - " + context.materialGroup[i].description) {
                  context.dataMaterial.material_group = context.materialGroup[i].code + " - " + context.materialGroup[i].description;
                  found = true;
                  break;
                }
              }

              if (!found) {
                context.dataMaterial.material_group = "";
              }
            }
            if (context.segmentCodes.length == 1) {
              context.dataMaterial.sg_code = context.segmentCodes[0].identity_relation_code;
              context.dependSegment(context.segmentCodes[0].identity_relation_code)
            } else if (context.segmentCodes.length == 0) {
              context.segmentCodes = []
              context.familyCodes = []
              context.classificationCodes = []
              context.commodityCodes = []
              context.dataMaterial.sg_code = "";
              context.dataMaterial.fm_code = "";
              context.dataMaterial.cl_code = "";
              context.dataMaterial.cm_code = "";
            } else {
              if (!context.segmentCodes.some((val) => val.identity_relation_code == context.dataMaterial.sg_code)) {
                context.familyCodes = []
                context.classificationCodes = []
                context.commodityCodes = []
                context.dataMaterial.sg_code = "";
                context.dataMaterial.fm_code = "";
                context.dataMaterial.cl_code = "";
                context.dataMaterial.cm_code = "";
              } else {
                for (var i = 0; i < context.segmentCodes.length; i++) {
                  if (context.dataMaterial.sg_code == context.segmentCodes[i].identity_relation_code) {
                    context.dataMaterial.sg_code = context.segmentCodes[i].identity_relation_code;
                    break;
                  } else {
                    continue;
                  }
                }
              }


            }



            // Mesc

            if (context.dataMaterial.mesc.main == "") {
              if (context.mescGroup.mainGroup.length == 1) {
                context.dataMaterial.mesc.main = context.dataMaterial.mesc.main = context.mergeMesc(context.mescGroup.mainGroup[0].code, context.mescGroup.mainGroup[0].key);
              } else if (context.mescGroup.mainGroup.length == 0) {
                Api(context, Mesc.get())
                  .onSuccess(response => {
                    context.mescGroup.mainGroup = response.data.data;
                    if (context.mescGroup.mainGroup.length == 0) {
                      context.dataMaterial.mesc.main = '';
                    } else if (context.mescGroup.mainGroup.length == 1) {
                      context.dataMaterial.mesc.main = context.mergeMesc(context.mescGroup.mainGroup[0].code, context.mescGroup.mainGroup[0].key)
                    }
                  })
                  .onError(error => {
                    context.mescGroup.mainGroup = [];
                  })
                  .call();
              }
            } else {
              if (context.mescGroup.mainGroup.length == 0) {
                context.dataMaterial.mesc.main = "";
              }
            }

            // Check Material Type
            if (context.materialType.length == 1) {
              context.dataMaterial.material_type =
                context.materialType[0].code +
                " - " +
                context.materialType[0].description;
            } else if (context.materialType.length == 0) {
              context.dataMaterial.material_type = "";
            } else {
              for (var i = 0; i < context.materialType.length; i++) {
                if (
                  context.dataMaterial.material_type ==
                  context.materialType[i].code +
                  " - " +
                  context.materialType[i].description
                ) {
                  context.dataMaterial.material_type =
                    context.materialType[i].code +
                    " - " +
                    context.materialType[i].description;
                  break;
                }
              }
            }

            // Check Nato Group
            if (context.natoGroupClass.length == 1) {
              context.dataMaterial.group_class =
                context.natoGroupClass[0].nsc +
                " - " +
                context.natoGroupClass[0].description;
            } else if (context.natoGroupClass.length == 0) {
              context.dataMaterial.group_class = "";
            } else {
              for (var i = 0; i < context.natoGroupClass.length; i++) {
                if (
                  context.dataMaterial.group_class ==
                  context.natoGroupClass[i].nsc +
                  " - " +
                  context.natoGroupClass[i].description
                ) {
                  context.dataMaterial.group_class =
                    context.natoGroupClass[i].nsc +
                    " - " +
                    context.natoGroupClass[i].description;
                  break;
                }
              }
            }

            if (!!response.data.data.sg_code) {
              context.dataMaterial.sg_code = response.data.data.sg_identity_relation_code;
            }

            if (!!response.data.data.fm_code) {
              context.dataMaterial.fm_code = response.data.data.fm_identity_relation_code;
            }

            if (!!response.data.data.cl_code) {
              context.dataMaterial.cl_code = response.data.data.cl_identity_relation_code;
            }

            if (!!response.data.data.cm_code) {
              context.dataMaterial.cm_code = response.data.data.cm_identity_relation_code;
            }

          })
          .onError(error => {
            context.materialGroup = [];
            context.materialType = [];
            context.natoGroupClass = [];
            // context.mescGroup.mainGroup = [];
          })
          .onFinish(function () {
            context.selectSearch.ngcLoading = false;
            context.selectSearch.mtLoading = false;
            context.selectSearch.mgLoading = false;
            context.selectSearch.unspsc = false;
          })
          .call();
      } else {
        Api(context, Mesc.get())
          .onSuccess(response => {
            context.mescGroup.mainGroup = response.data.data;
          })
          .onError(error => {
            context.mescGroup.mainGroup = [];
          })
          .call();
      }
    },
    getLocationCode(plant_code) {
      if (plant_code != "") {
        let context = this;
        context.part_storage_location.forEach(function (item) {
          Api(context, locationCode.getByPlant(item.plant_code))
            .onSuccess(function (response) {
              context.locationCode[item.plant_code] = response.data.data;
            })
            .call();
        });
      }
    },
    showCharValue() {
      // this.requestCalls++;

      let context = this;
      const inc = context.dataMaterial.inc.split(" - ");
      const item_type = context.dataMaterial.item_type.split(" - ");
      if (
        context.dataMaterial.inc != "" &&
        context.dataMaterial.item_type != ""
      ) {
        context.btnShowCharValue.onLoading = true;
        this.onLoad.classification = false;
        Api(context, characteristicItemName.getByType(inc[0], item_type[0], context.currentRow.material_number))
          .onSuccess(function (response) {
            context.partCharValue.id = [];
            context.partCharValue.cin_code = [];
            context.partCharValue.characteristic = [];
            context.partCharValue.value = [];
            context.partCharValue.view = [];
            context.partCharValue.multipleChar = [];
            context.partCharValue.code = [];
            context.partCharValue.mandatory = [];
            for (var i = 0; i < response.data.data.data_value.length; i++) {
              context.partCharValue.cin_code[i] = response.data.data.data_value[i].cin_code;
              context.partCharValue.characteristic[i] = response.data.data.data_value[i].characteristic;
              context.partCharValue.view[i] = true;
              context.partCharValue.value[i] = response.data.data.data_value[i].characteristic_value;
              context.partCharValue.multipleChar[i] = response.data.data.data_value[i].single_or_multiple;
              context.partCharValue.code[i] = response.data.data.data_value[i].code;
              context.partCharValue.mandatory[i] = response.data.data.data_value[i].mandatory ? true : false;
            }
            context.oldValue.data = response.data.data.data_old_value
          })
          .onError(function (err) {
            if (err.response.status == 404) {
              context.partCharValue.id = [];
              context.partCharValue.cin_code = [];
              context.partCharValue.characteristic = [];
              context.partCharValue.value = [];
              context.partCharValue.view = false;
              context.partCharValue.multipleChar = [];
              context.partCharValue.code = [];
              context.oldValue.data = []
            }
          })
          .onFinish(function () {
            context.btnShowCharValue.onLoading = false;
            context.partCharShow = true;
            context.onLoad.classification = true;
            // context.requestCalls--;
          })
          .call();
      } else {
        this.$notify({
          message: this.tt("please_complete_data", {
            title: "Item Name & Item Type"
          }),
          type: "danger",
          verticalAlign: "bottom",
          horizontalAlign: "left"
        });
        return false;
      }
    },
    selectedCinCode(cin_code) {
      let context = this;
      context.charValue = [];
      Api(
        context,
        characteristicItemName.get_option_characteristic_value(cin_code, {
          per_page: "none"
        })
      )
      .onSuccess(function (response) {
        response.data.data.data.forEach(function (item, index) {
          context.charValue[index] = {
            code: item["cin_code"],
            value: item["characteristic_value"]
          };
        });
      })
      .onError(function (error) {
        if (error.response.status == 404) {
          context.charValue = [];
        }
      })
      .call();
    },
    selectPlant(item) {
      this.plant.plant_code = item.value;
    },
    selectEquipmentCode(item) {
      this.equipment.equipment_code = item.value;
    },
    queryPlant(query) {
      var links = this.plantCode;
      this.plantList = query ? links.filter(this.createFilter(query)) : links;
    },
    queryEquipmentCode(query, cb) {
      var links = this.equipmentCode;
      var results = query ? links.filter(this.createFilter(query)) : links;
      cb(results);
    },
    querySearchItemName(query) {
      if (query.length > 2) {
        clearTimeout(this.selectSearch.loadTimeout);
        let context = this;
        this.selectSearch.sitLoading = true;

        this.selectSearch.loadTimeout = setTimeout(() => {
          Api(
            context,
            standardItemName.get(null, { per_page: "none", item_name: query })
          )
            .onSuccess(function (response) {
              context.standardItemName = response.data.data.data.data;
              context.selectSearch.sitLoading = false;
            })
            .onError(function (error) {
              context.standardItemName = [];
              context.selectSearch.sitLoading = false;
            })
            .call();
        }, 200);
      }
    },
    queryCompany(query) {
      if (query.length > 1) {
        clearTimeout(this.selectSearch.loadTimeout);
        let context = this;
        this.selectSearch.companyLoading = true;

        this.selectSearch.loadTimeout = setTimeout(() => {
          Api(
            context,
            companyCode.get(null, { per_page: "none", search: query })
          )
            .onSuccess(function (response) {
              context.companyCode = response.data.data.data.data;
              context.selectSearch.companyLoading = false;
            })
            .onError(function (error) {
              context.companyCode = [];
              context.selectSearch.companyLoading = false;
            })
            .call();
        }, 200);
      }
    },
    querySearchGroupClass(query) {
      clearTimeout(this.selectSearch.loadTimeout);
      let context = this;
      this.selectSearch.ngcLoading = true;
      this.selectSearch.loadTimeout = setTimeout(() => {
        Api(
          context,
          natoGroupClass.get(null, { per_page: "none", search: query })
        )
          .onSuccess(function (response) {
            context.natoGroupClass = response.data.data.data.data;
            context.selectSearch.ngcLoading = false;
          })
          .onError(function (error) {
            if (error.response.status == 404) {
              context.natoGroupClass = [];
              context.selectSearch.ngcLoading = false;
            }
          })
          .call();
      }, 200);
    },
    querySearchMtype(query) {
      clearTimeout(this.selectSearch.loadTimeout);
      let context = this;
      this.selectSearch.mtLoading = true;
      this.selectSearch.loadTimeout = setTimeout(() => {
        Api(
          context,
          materialType.get(null, { per_page: "none", search: query })
        )
          .onSuccess(function (response) {
            context.materialType = response.data.data.data.data;
            context.selectSearch.mtLoading = false;
          })
          .onError(function (error) {
            if (error.response.status == 404) {
              context.materialType = [];
              context.selectSearch.mtLoading = false;
            }
          })
          .call();
      }, 200);
    },
    querySearchEquipment(query) {
      if (query.length > 2) {
        clearTimeout(this.selectSearch.loadTimeout);
        let context = this;
        this.selectSearch.equipmentLoading = true;
        this.selectSearch.loadTimeout = setTimeout(() => {
          Api(
            context,
            equipmentCode.get(null, { per_page: "none", search: query })
          )
            .onSuccess(function (response) {
              context.equipmentList = response.data.data.data.data;
              context.selectSearch.equipmentLoading = false;
            })
            .onError(function (error) {
              if (error.response.status == 404) {
                context.equipmentList = [];
                context.selectSearch.equipmentLoading = false;
              }
            })
            .call();
        }, 200);
      }
    },
    querySearchManCode(query) {
      if (query.length > 1) {
        clearTimeout(this.selectSearch.loadTimeout);
        let context = this;
        this.selectSearch.mancodeLoading = true;
        this.selectSearch.loadTimeout = setTimeout(() => {
          Api(
            context,
            manufacture.get(null, { per_page: "none", search: query })
          )
            .onSuccess(function (response) {
              context.masterManufacture = response.data.data.data.data;
              context.selectSearch.mancodeLoading = false;
            })
            .onError(function (error) {
              if (error.response.status == 404) {
                context.masterManufacture = [];
                context.selectSearch.mancodeLoading = false;
              }
            })
            .call();
        }, 200);
      }
    },
    querySearchDrawingNumber(query) {
      if (query.length > 2) {
        clearTimeout(this.selectSearch.loadTimeout);
        let context = this;
        this.selectSearch.drawingNumLoading = true;
        this.selectSearch.loadTimeout = setTimeout(() => {
          Api(
            context,
            drawingMaster.get(null, { per_page: "none", search: query })
          )
            .onSuccess(function (response) {
              context.drawingMaster = response.data.data.data.data;
              context.selectSearch.drawingNumLoading = false;
            })
            .onError(function (error) {
              if (error.response.status == 404) {
                context.drawingMaster = [];
                context.selectSearch.drawingNumLoading = false;
              }
            })
            .call();
        }, 200);
      }
    },
    querySearchMgroup(query) {
      clearTimeout(this.selectSearch.loadTimeout);
      let context = this;
      this.selectSearch.mgLoading = true;
      this.selectSearch.loadTimeout = setTimeout(() => {
        Api(
          context,
          materialGroup.get(null, { per_page: "none", search: query })
        )
          .onSuccess(function (response) {
            context.materialGroup = response.data.data.data.data;
            context.selectSearch.mgLoading = false;
          })
          .onError(function (error) {
            if (error.response.status == 404) {
              context.materialGroup = [];
              context.selectSearch.mgLoading = false;
            }
          })
          .call();
      }, 200);
    },
    querySearchCtype(query) {
      clearTimeout(this.selectSearch.loadTimeout);
      let context = this;
      this.selectSearch.ctLoading = true;
      this.selectSearch.loadTimeout = setTimeout(() => {
        Api(context, catalogType.get(null, { per_page: "none", search: query }))
          .onSuccess(function (response) {
            context.catalogType = response.data.data.data.data;
            context.selectSearch.ctLoading = false;
          })
          .onError(function (error) {
            if (error.response.status == 404) {
              context.catalogType = [];
              context.selectSearch.ctLoading = false;
            }
          })
          .call();
      }, 200);
    },
    querySearchUom(query) {
      clearTimeout(this.selectSearch.loadTimeout);
      let context = this;
      this.selectSearch.uomLoading = true;
      this.selectSearch.loadTimeout = setTimeout(() => {
        Api(
          context,
          unitOfMeasure.get(null, { per_page: "none", search: query })
        )
          .onSuccess(function (response) {
            context.unitOfMeasure = response.data.data.data.data;
            context.selectSearch.uomLoading = false;
          })
          .onError(function (error) {
            if (error.response.status == 404) {
              context.unitOfMeasure = [];
              context.selectSearch.uomLoading = false;
            }
          })
          .call();
      }, 200);
    },
    querySearchMatNum(query) {
      clearTimeout(this.selectSearch.loadTimeout);
      let context = this;
      this.selectSearch.matnumFromLoading = true;
      this.selectSearch.loadTimeout = setTimeout(() => {
        Api(context, WorkbenchMaterial.getMaterialNumber({ search: query }))
          .onSuccess(function (response) {
            context.materialNumber = response.data.data;
            context.selectSearch.matnumFromLoading = false;
          })
          .onError(function (error) {
            if (error.response.status == 404) {
              context.materialNumber = [];
              context.selectSearch.matnumFromLoading = false;
            }
          })
          .call();
      });
    },
    getItemType() {
      let context = this;
      Api(context, ItemType.getItemType())
        .onSuccess(function (response) {
          context.itemType = response.data.data;
        })
        .onError(function (error) {
          if (error.response.status == 404) {
            context.itemType = [];
          }
        })
        .onFinish(function () {
          context.requestCalls--;
        })
        .call();
    },
    getValue(queryString, cb) {
      var links = this.charValue;
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },
    getCatalogStatus() {
      let context = this;
      Api(context, catalogStatus.getByUser(null, { per_page: "none" }))
        .onSuccess(function (response) {
          context.catalogStatus = response.data.data.data;
        })
        .onError(function (error) {
          if (error.response.status == 404) {
            context.catalogStatus = [];
          }
        })
        .call();
    },
    // queryCatalogStatus(query) {
    //  clearTimeout(this.selectSearch.loadTimeout);
    //  let context = this;
    //  this.selectSearch.ctLoading = true;
    //  this.selectSearch.loadTimeout = setTimeout(() => {
    //      Api(context, catalogStatus.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
    //             context.catalogStatus = response.data.data.data.data;
    //             context.selectSearch.ctLoading = false;
    //         }).onError(function(error) {
    //             if (error.response.status == 404) {
    //                 context.catalogStatus = [];
    //                 context.selectSearch.ctLoading = false;
    //             }
    //         })
    //         .call()
    //  }, 200)
    // },
    createFilter(query) {
      return link => {
        return link.value.toLowerCase().indexOf(query.toLowerCase()) > -1;
      };
    },
    smartcatAttachment() {
      this.modalSmartcatAttachment = true;
    },
    checkDuplicate() {
      this.modalCheckDuplicate = true;
    },
    requiredTabNotify(tabName) {
      this.$notify({
        message: "Tab " + tabName + " is required",
        type: "danger",
        verticalAlign: "bottom",
        horizontalAlign: "left"
      });
    },
    addMultipleChar(key, cin_code, characteristic, view, mandatory) {
      this.partCharValue.cin_code.splice(key + 1, 0, cin_code);
      this.partCharValue.characteristic.splice(key + 1, 0, characteristic);
      this.partCharValue.value.splice(key + 1, 0, "");
      this.partCharValue.view.splice(key + 1, 0, view);
      this.partCharValue.multipleChar.splice(key + 1, 0, "");
      this.partCharValue.code.splice(key + 1, 0, 1);
      this.partCharValue.mandatory.splice(key + 1, 0, mandatory);
    },
    removeMultipleChar(key) {
      this.partCharValue.cin_code.splice(key, 1);
      this.partCharValue.characteristic.splice(key, 1);
      this.partCharValue.value.splice(key, 1);
      this.partCharValue.view.splice(key, 1);
      this.partCharValue.multipleChar.splice(key, 1);
      this.partCharValue.code.splice(key, 1);
      this.partCharValue.mandatory.splice(key, 1);
    },
    save() {
      if (this.catalog_status == "") {
        this.$notify({
          message: "Please choose catalog status",
          type: "danger",
          verticalAlign: "bottom",
          horizontalAlign: "left"
        });
      } else {
        this.confirmDialog(this.tt("confirm_update")).then(result => {
          let context = this;
          let formData = new FormData();
          let formDataCatStatus = new FormData();
          const inc = context.dataMaterial.inc.split(" - ");
          const item_type = context.dataMaterial.item_type.split(" - ");
          const group_class = context.dataMaterial.group_class.split(" - ");
          const uom = context.dataMaterial.uom.split(" - ");
          const material_type = context.dataMaterial.material_type.split(" - ");
          const material_group = context.dataMaterial.material_group.split(" - ");
          const catalog_type = context.dataMaterial.catalog_type.split(" - ");

          if (result.value) {
            // Handle Update Multiple Catalog Status by Material Number
            if (context.multipleChangeCatStatus.length > 0) {
              for (var i = 0; i < context.multipleChangeCatStatus.length; i++) {
                formDataCatStatus.append(
                  "material_number[" + i + "]",
                  context.multipleChangeCatStatus[i]
                );
                formDataCatStatus.append(
                  "cat_status_code[" + i + "]",
                  context.catalog_status
                );
              }
              Api(context, WorkbenchMaterial.multipleUpdateCatStatus(formDataCatStatus))
                .onSuccess(function (response) {
                  this.$notify({
                    message: this.tt("catalog_status_updated"),
                    type: "success",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                  });
                })
                .onError(function (error) {
                  this.$notify({
                    message: this.tt("catalog_status_error"),
                    type: "danger",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                  });
                })
                .call();
            }

            formData.append("cat_status_code", context.catalog_status);

            // Send Config Workbench
            formData.append("config_workbench_client", context.configCodification)

            // Update Basic Data
            if (
              (context.configBasicData.includes('item_name') && !!!!context.dataMaterial.inc) &&
              (context.configBasicData.includes('item_type') && !!!!context.dataMaterial.item_type) &&
              (context.configBasicData.includes('group_class') && !!!!context.dataMaterial.group_class) &&
              (context.configBasicData.includes('uom') && !!!!context.dataMaterial.uom) &&
              (context.configBasicData.includes('material_type') && !!!!context.dataMaterial.material_type) &&
              (context.configBasicData.includes('material_group') && !!!!context.dataMaterial.material_group) &&
              (context.configBasicData.includes('catalog_type') && !!!!context.dataMaterial.catalog_type)
              // context.dataMaterial.mesc.main != "" &&
              // context.dataMaterial.mesc.sub != "" &&
              // context.dataMaterial.mesc.subSub != "" 
            ) {
              if (context.configCodification == 'MESC') {
                if (context.dataMaterial.mesc.main != "" && context.dataMaterial.mesc.sub != "" && context.dataMaterial.mesc.subSub != "") {
                  var resultMesc = context.mergeResult();
                  formData.append("mesc", resultMesc);
                } else {
                  this.completeDataNotify("mesc")
                  return false;
                }
              } else if (context.configCodification == 'UNSPSC') {
                if (context.dataMaterial.cm_code != "" && context.dataMaterial.cl_code != "" && context.dataMaterial.fm_code != "" && context.dataMaterial.sg_code != "") {
                  formData.append("sg_code", context.dataMaterial.sg_code);
                  formData.append("fm_code", context.dataMaterial.fm_code);
                  formData.append("cl_code", context.dataMaterial.cl_code);
                  formData.append("cm_code", context.dataMaterial.cm_code);
                  formData.append("mesc", "");
                } else {
                  this.completeDataNotify("unspsc");
                  return false;
                }
              } else {
                formData.append("mesc", "");
              }

              formData.append("id_master", context.dataMaterial.id);
              formData.append("material_number", context.material_number);
              formData.append("inc", inc[0]);
              formData.append("item_type_code", item_type[0]);
              formData.append("nsc", group_class[0]);
              formData.append("uom_code", uom[0]);
              formData.append("mtype_code", material_type[0]);
              formData.append("mgroup_code", material_group[0]);
              formData.append("fab_non_fab", context.dataMaterial.fab_non_fab);
              formData.append("bom_non_bom", context.dataMaterial.bom_non_bom);
              // formData.append("new_material_number", context.dataMaterial.new_material_number);
              formData.append(
                "critical_non_critical",
                context.dataMaterial.critical_non_critical
              );
              formData.append("cat_type_code", catalog_type[0]);
              formData.append("note", context.dataMaterial.note);
            } else {
              this.completeDataNotify("basic_data_&_specification");
              return false;
            }
            
            // if (context.partCharValue.cin_code.length > 0) {
              for (var i = 0; i < context.partCharValue.cin_code.length; i++) {
                if (context.partCharValue.mandatory[i] == true) {
                  if (context.partCharValue.value[i] != "") {
                    formData.append(
                      "cin_code[" + i + "]",
                      context.partCharValue.cin_code[i]
                    );
                    // formData.append('characteristic_value['+i+']', (context.partCharValue.value[i] != undefined) ? context.partCharValue.value[i] : '')
                    formData.append(
                      "characteristic_value[" + i + "]",
                      context.partCharValue.value[i]
                    );
                    formData.append(
                      "view[" + i + "]",
                      context.partCharValue.view[i] == true ? "checked" : ""
                    );
                    formData.append(
                      "code[" + i + "]",
                      context.partCharValue.code[i]
                    );
                  } else {
                    context.$notify({
                      message: context.tt("fill_mandatory_characteristic"),
                      type: "danger",
                      verticalAlign: "bottom",
                      horizontalAlign: "left"
                    });
                    return false;
                  }
                } else {
                  formData.append(
                    "cin_code[" + i + "]",
                    context.partCharValue.cin_code[i]
                  );
                  // formData.append('characteristic_value['+i+']', (context.partCharValue.value[i] != undefined) ? context.partCharValue.value[i] : '')
                  formData.append(
                    "characteristic_value[" + i + "]",
                    context.partCharValue.value[i]
                  );
                  formData.append(
                    "view[" + i + "]",
                    context.partCharValue.view[i] == true ? "checked" : ""
                  );
                  formData.append(
                    "code[" + i + "]",
                    context.partCharValue.code[i]
                  );
                }
              }
            // } else {
            //   context.requiredTabNotify("Classification");
            //   return false;
            // }
            
            // Update MPN
            if (context.partMPN.manufacture_code.length > 0) {
              for (
                var i = 0;
                i < context.partMPN.manufacture_code.length;
                i++
              ) {
                if (
                  context.partMPN.manufacture_code[i] != "" &&
                  context.partMPN.source_type_code[i] != "" &&
                  context.partMPN.manufacture_type[i] != ""
                ) {
                  formData.append(
                    "part_manufacture_ref_id[" + i + "]",
                    context.partMPN.part_manufacture_ref_id[i]
                  );
                  formData.append(
                    "manufacture_code[" + i + "]",
                    context.partMPN.manufacture_code[i]
                  );
                  formData.append(
                    "source_type[" + i + "]",
                    context.partMPN.source_type_code[i]
                  );
                  formData.append(
                    "manufacture_type[" + i + "]",
                    context.partMPN.manufacture_type[i]
                  );
                  formData.append(
                    "manufacture_note[" + i + "]",
                    context.partMPN.manufacture_note[i]
                  );
                  formData.append(
                    "manufacture_ref[" + i + "]",
                    context.partMPN.manufacture_ref[i]
                  );
                  formData.append(
                    "mpn_shortdes[" + i + "]",
                    context.partMPN.shortdes[i]
                  );
                } else {
                  context.completeDataNotify("mpn");
                  return false;
                }
              }
            } else {
              formData.append("part_manufacture_ref_id", "");
              formData.append("manufacture_code", "");
            }

            // Update Plant
            if (context.partPlant.plant_code.length > 0) {
              for (var i = 0; i < context.partPlant.plant_code.length; i++) {
                if (
                  context.partPlant.plant_code[i] != "" &&
                  context.partPlant.company_code[i] != ""
                ) {
                  if (context.partPlant.id[i] == "") {
                    formData.append("part_plant_id[" + i + "]", "");
                  } else {
                    formData.append(
                      "part_plant_id[" + i + "]",
                      context.partPlant.id[i]
                    );
                  }
                  formData.append(
                    "plant_code[" + i + "]",
                    context.partPlant.plant_code[i]
                  );
                  formData.append(
                    "company_code[" + i + "]",
                    context.partPlant.company_code[i]
                  );
                }
              }
            } else {
              formData.append("part_plant_id", "");
              formData.append("plant_code", "");
            }

            // Update Equipment
            if (context.partEquipment.plant_code.length > 0) {
              for (
                var i = 0;
                i < context.partEquipment.plant_code.length;
                i++
              ) {
                if (
                  context.partEquipment.plant_code[i] != "" &&
                  context.partEquipment.equipment_code[i] != "" &&
                  context.partEquipment.qty_installed[i] != "" &&
                  context.partEquipment.drawing_number[i] != ""
                ) {
                  formData.append(
                    "part_equipment_code_id[" + i + "]",
                    context.partEquipment.id[i]
                  );
                  formData.append(
                    "plant_equipment[" + i + "]",
                    context.partEquipment.plant_code[i]
                  );
                  formData.append(
                    "equipment_code[" + i + "]",
                    context.partEquipment.equipment_code[i]
                  );
                  formData.append(
                    "qty_installed[" + i + "]",
                    context.partEquipment.qty_installed[i]
                  );
                  formData.append(
                    "drawing_number[" + i + "]",
                    context.partEquipment.drawing_number[i]
                  );
                  formData.append(
                    "equipment_shortdes[" + i + "]",
                    context.partEquipment.shortdes[i]
                  );
                } else {
                  context.completeDataNotify("equipment");
                  return false;
                }
              }
            } else {
              // if (item_type[0] == 'OEM' || context.dataMaterial.bom_non_bom == 'BOM') {
              //     context.requiredTabNotify('equipment');
              //     return false;
              // } else {
              formData.append("part_equipment_code_id", "");
              formData.append("plant_equipment", "");
              formData.append("equipment_code", "");
              // }
            }

            // Update Keyword
            if (context.partKeyword.keyword.length > 0) {
              for (var i = 0; i < context.partKeyword.keyword.length; i++) {
                if (context.partKeyword.keyword[i] != "") {
                  formData.append(
                    "part_keyword_id[" + i + "]",
                    context.partKeyword.id[i]
                  );
                  formData.append(
                    "keyword[" + i + "]",
                    context.partKeyword.keyword[i]
                  );
                } else {
                  context.completeDataNotify("keyword");
                  return false;
                }
              }
            } else {
              formData.append("part_keyword_id", "");
              formData.append("keyword", "");
            }

            // Update Part Attachment
            if (context.partAttachment.file_attachment_name.length > 0) {
              for (
                var i = 0;
                i < context.partAttachment.file_attachment_name.length;
                i++
              ) {
                if (
                  context.partAttachment.file_attachment_name.length > 0 &&
                  context.partAttachment.file_attachment_name[
                  context.partAttachment.file_attachment_name.length - 1
                  ] != ""
                ) {
                  formData.append(
                    "part_attachment_id[" + i + "]",
                    context.partAttachment.id[i]
                  );
                  formData.append(
                    "file_attachment_code[" + i + "]",
                    context.partAttachment.file_attachment_name[i]
                  );
                } else {
                  context.completeDataNotify("attachment");
                  return false;
                }
              }
            } else {
              formData.append("part_attachment_id", "");
              formData.append("file_attachment_code", "");
            }

            // Update Storage Location
            if (context.partStorageLoc.storage_location.length) {
              for (
                var i = 0;
                i < context.partStorageLoc.storage_location.length;
                i++
              ) {
                if (context.partStorageLoc.storage_location[i] != "") {
                  formData.append(
                    "part_storage_location_id[" + i + "]",
                    context.partStorageLoc.id[i]
                  );
                  formData.append(
                    "plant_storage[" + i + "]",
                    context.partStorageLoc.plant_code[i]
                  );
                  formData.append(
                    "storage_location[" + i + "]",
                    context.partStorageLoc.storage_location[i]
                  );
                  formData.append(
                    "bin_code[" + i + "]",
                    context.partStorageLoc.bin_location[i]
                  );
                  formData.append(
                    "soh[" + i + "]",
                    context.partStorageLoc.soh[i]
                  );
                } else {
                  context.completeDataNotify("storage_location");
                  return false;
                }
              }
            } else {
              formData.append("part_storage_location_id", "");
              formData.append("plant_storage", "");
              formData.append("storage_location", "");
            }

            // SEND DATA TO BACKEND
            this.requestCalls++;
            Api(context, WorkbenchMaterial.updateWorkBenchMaterial(formData, context.material_number))
              .onSuccess(function (response) {
                context.$notify({
                  message: response.data.message,
                  type: "success",
                  verticalAlign: "bottom",
                  horizontalAlign: "left"
                });
              })
              .onError(function (err) {
                context.$notify({
                  message: err.response.data.message,
                  type: "danger",
                  verticalAlign: "bottom",
                  horizontalAlign: "left"
                });
              })
              .onFinish(function () {
                context.handleCurrentChange(context.currentRow)
                // context.onLoad.detail = true;
                context.get();
                // context.dependOnItemName();
                context.$refs.multipleTable.setCurrentRow(context.currentRow);
                context.requestCalls--;
              })
              .call();
          }
        });
      }
    },
    cloneData() {
      let context = this;
      let formData = new FormData();
      if (this.materialNumberFrom == "") {
        this.completeDataNotify("material_from");
      } else {
        this.btnClone.onLoading = true;
        formData.append("material_number_from", this.materialNumberFrom);
        formData.append("material_number_to", this.materialNumberTo);
        formData.append("cat_status", this.catalog_status_clone);
        Api(context, WorkbenchMaterial.cloneAllData(formData))
          .onSuccess(function (response) {
            context.$notify({
              message: context.tt("clone_all_data_success"),
              type: "success",
              verticalAlign: "bottom",
              horizontalAlign: "left"
            });
            context.showCopyModal = false;
          })
          .onError(function (err) {
            context.$notify({
              message: context.tt("clone_all_data_error"),
              type: "danger",
              verticalAlign: "bottom",
              horizontalAlign: "left"
            });
          })
          .onFinish(function () {
            context.btnClone.onLoading = false;
            context.$refs.materialNumberFrom.value = "";
            context.$refs.materialNumberFrom.label = "";
            context.get();
            context.$refs.multipleTable.setCurrentRow(context.currentRow);
            context.catalog_status_clone = "";
          })
          .call();
      }
    },
    copyData() {
      let context = this;
      let formData = new FormData();
      if (this.materialNumberFrom == "") {
        this.completeDataNotify("material_from");
      } else {
        this.btnCopy.onLoading = true;
        formData.append("material_from", this.materialNumberFrom);
        formData.append("material_to", this.materialNumberTo);
        Api(context, WorkbenchMaterial.copyAllValue(formData))
          .onSuccess(function (response) {
            context.$notify({
              message: context.tt("copy_all_value_success"),
              type: "success",
              verticalAlign: "bottom",
              horizontalAlign: "left"
            });
            context.showCopyModal = false;
          })
          .onError(function (err) {
            context.$notify({
              message: context.tt("copy_all_value_error"),
              type: "danger",
              verticalAlign: "bottom",
              horizontalAlign: "left"
            });
          })
          .onFinish(function () {
            context.btnCopy.onLoading = false;
            context.$refs.materialNumberFrom.value = "";
            context.$refs.materialNumberFrom.label = "";
            context.get();
            context.handleCurrentChange(context.currentRow)
            context.catalog_status_clone = "";
          })
          .call();
      }
    },
    setCurrentTable(materialNumber) {
      let resultRow = this.table.data.findIndex(
        element => element.material_number == materialNumber
      );
      // let resultRow = this.table.data.find(element => element.material_number == materialNumber);
      let data = this.table.data[resultRow];
      this.$refs.multipleTable.handleCurrentChange(data);
      //this.$refs.multipleTable.updateCurrentRow(data);
      // this.handleCurrentChange(data);
    },
    copyValue() {
      this.showCopyModal = true;
    },
    heightTable() {
      setTimeout(() => {
        let tableHeight = document.querySelector(".el-table__body-wrapper");
        let tableHeightSecond = document.querySelector(
          ".el-table__body-wrapper"
        );
        let tableScroll = document.querySelector(".el-table.customTable")
        tableHeight.setAttribute("style", "height:250px;");
        tableHeightSecond.setAttribute("style", "height:250px;");
        tableScroll.setAttribute("style", "min-height:250px;");
        tableScroll.setAttribute("style", "max-height:250px;");
      }, 1500);
    },
    mergeMesc(code, key) {
      let result = code + " " + key;
      return result;
    },
    splitMesc(mesc) {
      let mescSplit = mesc.split(" ");
      let result = {
        code: mescSplit[0],
        key: mescSplit[1]
      };
      return result;
    },
    getSubGroup() {
      let context = this;
      let inc = 0;
      if (this.dataMaterial.inc != "") {
        inc = this.dataMaterial.inc.split(' ')[0];
      }

      if (
        this.dataMaterial.mesc.main != null &&
        this.mescGroup.mainGroup.length > 0
      ) {
        let mesc = this.splitMesc(this.dataMaterial.mesc.main);
        let key = mesc.key;
        Api(context, Mesc.getSubGroup(inc, key))
          .onSuccess(response => {
            context.mescGroup.subGroup = response.data.data;
            if (context.mescGroup.subGroup.length < 1) {
              context.mescGroup.subSubGroup = [];
            }

            if (context.mescGroup.subGroup.length == 1) {
              context.dataMaterial.mesc.sub = context.mergeMesc(
                context.mescGroup.subGroup[0].code,
                context.mescGroup.subGroup[0].key
              );
            } else if (context.mescGroup.subGroup.length == 0) {
              context.dataMaterial.mesc.sub = "";
            }
          })
          .onError(error => {
            context.mescGroup.subGroup = [];
          })
          .call();
      } else {
        this.mescGroup.subGroup = [];
      }
    },
    getSubSubGroup() {
      let context = this;

      if (
        this.dataMaterial.mesc.sub != null &&
        this.mescGroup.subGroup.length > 0
      ) {
        let mesc = this.splitMesc(this.dataMaterial.mesc.sub);
        let key = mesc.key;
        Api(context, Mesc.getSubSubGroup(key))
          .onSuccess(response => {
            context.mescGroup.subSubGroup = response.data.data;
            if (context.mescGroup.subSubGroup.length == 1) {
              context.dataMaterial.mesc.subSub = context.mergeMesc(
                context.mescGroup.subSubGroup[0].code,
                context.mescGroup.subSubGroup[0].key
              );
            } else if (context.mescGroup.subSubGroup.length == 0) {
              context.dataMaterial.mesc.subSub = "";
            }
          })
          .onError(error => {
            context.mescGroup.subSubGroup = [];
          })
          .call();
      } else {
        this.mescGroup.subSubGroup = [];
      }
    },
    mergeResult() {
      let main = this.splitMesc(this.dataMaterial.mesc.main).code;
      let sub = this.splitMesc(this.dataMaterial.mesc.sub).code;
      let subSub = this.splitMesc(this.dataMaterial.mesc.subSub).code;

      let result = main.concat("", sub).concat("", subSub);
      return result;
    },
    dependSegment(value) {
      if (this.dataMaterial.inc != "") {
        let inc = this.dataMaterial.inc.split(' - ')[0];
        this.selectSearch.unspsc = true
        let context = this;
        Api(context, standardItemName.children_unspsc(inc, value)).onSuccess((response) => {
          context.familyCodes = response.data.data
          let check = context.familyCodes.some((val) => val.identity_relation_code == context.dataMaterial.fm_code)
          if (!check) {
            context.dataMaterial.fm_code = ""
            context.dataMaterial.cl_code = ""
            context.dataMaterial.cm_code = ""
          }
        }).onError((error) => {
          context.familyCodes = []
          context.dataMaterial.fm_code = ""
          context.dataMaterial.cl_code = ""
          context.dataMaterial.cm_code = ""
        }).onFinish(() => {
          context.selectSearch.unspsc = false
          if (context.familyCodes.length == 1) {
            context.dataMaterial.fm_code = context.familyCodes[0].identity_relation_code
          }
        }).call()
      }

    },
    dependFamily(value) {
      if (this.dataMaterial.inc != "") {
        let inc = this.dataMaterial.inc.split(' - ')[0];
        let context = this;
        this.selectSearch.unspsc = true
        Api(context, standardItemName.children_unspsc(inc, value)).onSuccess((response) => {
          context.classificationCodes = response.data.data
          let check = context.classificationCodes.some((val) => val.identity_relation_code == context.dataMaterial.cl_code)
          if (!check) {
            context.dataMaterial.cl_code = ""
            context.dataMaterial.cm_code = ""
          }
        }).onError((error) => {
          context.classificationCodes = []
          context.dataMaterial.cl_code = ""
          context.dataMaterial.cm_code = ""
        }).onFinish(() => {
          context.selectSearch.unspsc = false
          if (context.classificationCodes.length == 1) {
            context.dataMaterial.cl_code = context.classificationCodes[0].identity_relation_code
          }
        }).call()
      }
    },
    dependClassification(value) {
      if (this.dataMaterial.inc != "") {
        let context = this;
        var inc = this.dataMaterial.inc.split(' - ')[0];
        this.selectSearch.unspsc = true
        Api(context, standardItemName.children_unspsc(inc, value)).onSuccess((response) => {
          context.commodityCodes = response.data.data
          let check = context.commodityCodes.some((val) => val.identity_relation_code == context.dataMaterial.cm_code)
          if (!check) {
            context.dataMaterial.cm_code = ""
          }
        }).onError((error) => {
          context.commodityCodes = []
          context.dataMaterial.cm_code = ""
        }).onFinish(() => {
          context.selectSearch.unspsc = false
          if (context.commodityCodes.length == 1) {
            context.dataMaterial.cm_code = context.commodityCodes[0].identity_relation_code
          }
        }).call()
      }
    },
    configWorkbenchTable() {
      if (this.authUserConfig) {
        let workbenchTable = []
        
        Api(this, WorkbenchMaterial.get_opted_colomn_config_user_on_end_user())
        .onSuccess((response) => {
          workbenchTable           = response.data.data.valueOptedCol
          const allCompletedColomn = ['cat_by', 'material_number', 'item_name', 'short_description', 'source_description', 'uom', 'cat_type', 'cat_status', 'catalogued_by', 'short_length', 'new_material_number', 'status_report']

          if (Array.isArray(workbenchTable)) {
            allCompletedColomn.forEach(element => {
              if (workbenchTable.includes(element)) {
                this.checkedColumn[element] = true
                this.checked[element]       = true 
              } else {
                this.checkedColumn[element] = false
                this.checked[element]       = false
              }
            })
          }

        }).onError((error) => {
          return ""
        }).call()        
      } else {
        return ""
      }
    },
    changeChecked(key) {
      this.checkedColumn[key] = !this.checkedColumn[key]
      if (this.checkedColumn[key] == true) {
        this.checked[key] = true
      } else {
        this.checked[key] = false
      }
      // UPDATE COLOMN CONFIG DI USERS
      const optedColomn = Object.keys(this.checkedColumn).filter(item => this.checkedColumn[item] === true).join(',')
      const returnVal   = JSON.stringify({workbench: {table: optedColomn}})
      Api(this, WorkbenchMaterial.workbench_filter_end_user_config({optedColomn : returnVal})).call()

      this.$forceUpdate()
    },
    handleChooseAttachment(data) {
      // tutup modal smartcat attachment
      this.modalSmartcatAttachment = false;

      // add new line attachment
      this.partAttachment.id.push("");
      this.partAttachment.file_attachment_name.push(data);
    },
  }
};
</script>

<style>
#draggable-container {
  position: absolute;
  z-index: 9;
  right: 540px;
  top: 50px;
  width: 400px;
  /* height:540px; */
}

#draggable-header {
  z-index: 10;
}

.fa.fa-trash:hover {
  cursor: pointer;
}

#tableSelect.el-autocomplete {
  min-width: 200px;
}

.customTable td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.el-table__body tr.current-row>td {
  background-color: #ffe391 !important;
}

#tableClassification,
.table th,
.table td {
  padding: 0.1rem;
}

.table td .el-input--small .el-input__inner {
  height: 20px;
  line-height: 32px;
}

#tableSelect.el-select .el-input .el-input__inner {
  height: 25px;
}

#tableSelect.el-input__inner {
  height: 25px;
  margin-top: 5px;
}

#tableSelectInput {
  height: 25px;
  margin-top: 5px;
}

.card-header .source {
  padding: 10px;
}

#saveButton {
  height: 25px;
  padding: 0 15px;
}

.header-source {
  font-size: 11px;
}

.el-select-dropdown__item {
  overflow: visible;
}

.el-scrollbar__wrap {
  max-width: 420px;
}

#tableClassification .el-autocomplete .el-input.el-input--small {
  width: 300px;
}

.el-tooltip__popper {
  max-width: 200px;
}

/* .context {
    position: fixed;
    background: white;
    z-index: 999;
    outline: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    height: 25px;
    width: 100px;
    padding-top:1px;
    padding-bottom:1px;
    padding-left:1px;
    font-size: 15px;
    text-align: center;
} */

@media (min-width: 992px) {
  .text-lg-right {
    text-align: right;
  }
}
</style>
